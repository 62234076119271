import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ProfileService from '@services/profile-service/profile-service';
import { useAnalytics } from '@hooks/use-analytics';
import { findPathByAlias } from '@routes/routesList';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import './preferred-dealer-modal.scss';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { PreferredDealerModalContentProps } from '@smart-tiles/smart-tile-preferred-dealer/hook/use-preferred-dealer-modal-content';
import BingService, {
    BingDealerInfo,
} from '@services/bing-service/bing-service';
import HttpService from '@services/http-service/http-service';
import {
    EU_PREFERRED_DEALER_ID_PREFIXES,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import { Dealer, EUDealer } from '@models/preferred-dealer';
import { VehicleDetail } from '@models/profile-with-vehicles';
import EuBingService from '@services/eu-bing-service/eu-bing-service';
import AuthenticationService from '@services/authentication-service/authentication-service';

interface Props {
    isVisible: boolean;
    onClose?: () => void;
    preferredDealerModalContent: PreferredDealerModalContentProps;
}

export interface VehicleOptions {
    model: string;
    nickname: string;
    vin: string;
    year: string;
    preferredDealerCode?: string;
    preferredDealerName?: string;
    preferredDealerAddress?: string;
    preferredDealerCityStateZip?: string;
    preferredDealerPhoneNumber?: string;
    preferredDealerLatitude?: string;
    preferredDealerLongitude?: string;
    noDealerSelectedText?: string;
    dealerInfoError?: string;
    dealerObject?: Dealer;
    mapLoaded?: boolean;
}

const PreferredDealerModal = (props: Props) => {
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const [fireAnalytics] = useAnalytics();
    const [profileData, setProfileData] = useState<any>(null);
    const [vehicleData, setVehicleData] = useState<VehicleOptions[]>(null);
    const [hideDealerInfo, setHideDealerInfo] = useState<boolean>(false);
    const profileService = new ProfileService();
    const authenticationService = new AuthenticationService();
    const httpService = HttpService;
    const bingService = new BingService(httpService);
    const { setContext, resetContext } = useModalContext();

    const closeOrCancelModal = () => {
        props.onClose();
        fireAnalytics('cancelPreferredDealerModalOnclickEvent');
        resetContext();
    };

    const checkRequiredDealerInfoExists = (dealerInfo) => {
        return (
            !!dealerInfo?.dealerName &&
            !!dealerInfo?.streetAddress &&
            !!dealerInfo?.city &&
            !!dealerInfo?.state &&
            !!dealerInfo?.zip &&
            !!dealerInfo?.phone
        );
    };

    const checkRequiredDealerInfoExistsEu = (dealerInfo: EUDealer): boolean => {
        return Boolean(
            dealerInfo?.DealerName &&
                dealerInfo?.AddressLine1 &&
                dealerInfo?.Locality &&
                dealerInfo?.PostCode
        );
    };

    const getVehicleWithDealerDataEu = async (
        vehicle: VehicleDetail
    ): Promise<VehicleOptions> => {
        const { modelName, vin, modelYear, nickName, preferredDealer } =
            vehicle;

        const listItem: VehicleOptions = {
            nickname: nickName,
            model: modelName,
            year: modelYear,
            vin: vin,
            preferredDealerCode: preferredDealer,
        };

        if (isEu && vehicle?.preferredDealer) {
            let dealerInfo: EUDealer;

            await EuBingService.searchDealersByProperties(1, null, {
                DealerId:
                    EU_PREFERRED_DEALER_ID_PREFIXES.find(
                        (country) =>
                            country.country === appConfig.currentCountryCode
                    ).prefix + vehicle?.preferredDealer,
            }).then((response) => {
                if (response) {
                    dealerInfo = {
                        ...response[0],
                        DealerID: vehicle?.preferredDealer.substring(2),
                    };
                }
            });

            const allRequiredDealerInfoExists: boolean =
                checkRequiredDealerInfoExistsEu(dealerInfo);

            if (allRequiredDealerInfoExists) {
                listItem.preferredDealerName = dealerInfo?.DealerName;
                listItem.preferredDealerAddress = dealerInfo?.AddressLine1;
                listItem.preferredDealerCityStateZip = `${dealerInfo?.Locality}, ${dealerInfo?.PostCode}`;
                listItem.preferredDealerPhoneNumber = dealerInfo?.PrimaryPhone;
            } else {
                listItem.dealerInfoError =
                    props.preferredDealerModalContent?.dealerInfoRetrievalError;
            }
        } else {
            listItem.noDealerSelectedText =
                props.preferredDealerModalContent?.noDealerSelectedText;
        }

        return listItem;
    };

    const getVehicleWithDealerData = async (
        vehicle: VehicleDetail
    ): Promise<VehicleOptions> => {
        const { modelName, vin, modelYear, nickName, preferredDealer } =
            vehicle;

        const listItem: VehicleOptions = {
            nickname: nickName,
            model: modelName,
            year: modelYear,
            vin: vin,
            preferredDealerCode: preferredDealer,
        };

        if (vehicle?.preferredDealer) {
            let dealerInfoArray: BingDealerInfo[] = null;

            try {
                dealerInfoArray = await bingService.getDealerInfoById(
                    vehicle.preferredDealer
                );
            } catch (e) {
                setHideDealerInfo(true);
            }

            let dealerInfo: BingDealerInfo;

            if (dealerInfoArray?.length > 0) {
                dealerInfo = dealerInfoArray[0];
            }
            const allRequiredDealerInfoExists =
                checkRequiredDealerInfoExists(dealerInfo);

            if (allRequiredDealerInfoExists) {
                listItem.preferredDealerName = dealerInfo.dealerName;
                listItem.preferredDealerAddress = dealerInfo.streetAddress;
                listItem.preferredDealerCityStateZip = `${dealerInfo.city}, ${dealerInfo.state} ${dealerInfo.zip}`;
                listItem.preferredDealerPhoneNumber = dealerInfo.phone;
            } else {
                listItem.dealerInfoError =
                    props.preferredDealerModalContent?.dealerInfoRetrievalError;
            }
        } else {
            listItem.noDealerSelectedText =
                props.preferredDealerModalContent?.noDealerSelectedText;
        }

        return listItem;
    };

    const buildModalData = async () => {
        if (!profileData || !props.preferredDealerModalContent) {
            return null;
        }

        const vehiclesWithPreferredDealerData = await Promise.all(
            profileData?.vehicles.map(
                isEu ? getVehicleWithDealerDataEu : getVehicleWithDealerData
            )
        );

        setVehicleData(vehiclesWithPreferredDealerData);
    };

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                profileService.request().then((profile) => {
                    if (profile) setProfileData(profile);
                    else setProfileData(null);
                });
            }
        });

        props.isVisible === true && fireAnalytics('ownerPreferredDealerGarage');
    }, [props.isVisible === true]);

    useEffect(() => {
        if (
            profileData &&
            props.preferredDealerModalContent &&
            props.isVisible
        ) {
            (async () => await buildModalData())();
        }
    }, [profileData, props.preferredDealerModalContent]);

    const handleVehicleChoice = (vehicle: VehicleOptions): void => {
        const nameplate = `${vehicle.year} ${vehicle.model}`;

        if (vehicle.preferredDealerCode) {
            fireAnalytics('editVehiclePreferredDealerOnclickEvent', '', {
                nameplate,
            });
        } else {
            fireAnalytics('selectVehiclePreferredDealerOnclickEvent', '', {
                nameplate,
            });
        }
    };

    const preferredDealerModalHeader = () => {
        return (
            <h2
                className={`fmc-type--heading5 ${
                    isLincoln ? 'fmc-mb-6 fmc-pb-1' : ''
                }`}
                data-testid="preferred-dealer-modal-header"
            >
                {props.preferredDealerModalContent?.headerText}
            </h2>
        );
    };

    const preferredDealerVehicleInfo = (
        vehicle: VehicleOptions,
        index: number
    ) => {
        return (
            <>
                <p
                    className={`fmc-type--body1 ${
                        isLincoln ? 'fds-color__text--primary' : ''
                    }`}
                    data-testid={`preferred-dealer-modal-ymm-${index}`}
                >
                    {vehicle.year} {vehicle.model}
                </p>
                {vehicle.vin &&
                props.preferredDealerModalContent?.vinLabelText ? (
                    <p className="fmc-type--content2">
                        {props.preferredDealerModalContent.vinLabelText}:{' '}
                        {vehicle.vin}
                    </p>
                ) : null}
            </>
        );
    };

    const preferredDealerPersonalInfo = (
        vehicle: VehicleOptions,
        index: number
    ) => {
        return (
            <>
                {vehicle.preferredDealerName && (
                    <p
                        className="preferred-dealer-modal__dealer-name"
                        data-testid={`preferred-dealer-modal-name-${index}`}
                    >
                        {vehicle.preferredDealerName}
                    </p>
                )}
                {vehicle.preferredDealerAddress && (
                    <p
                        className="preferred-dealer-modal__dealer-info"
                        data-testid={`preferred-dealer-modal-address-${index}`}
                    >
                        {vehicle.preferredDealerAddress}
                    </p>
                )}
                {vehicle.preferredDealerCityStateZip && (
                    <p
                        className="preferred-dealer-modal__dealer-info"
                        data-testid={`preferred-dealer-modal-citystatezip-${index}`}
                    >
                        {vehicle.preferredDealerCityStateZip}
                    </p>
                )}
                {vehicle.preferredDealerPhoneNumber && (
                    <p
                        className="preferred-dealer-modal__dealer-info"
                        data-testid={`preferred-dealer-modal-phone-${index}`}
                    >
                        {vehicle.preferredDealerPhoneNumber}
                    </p>
                )}
            </>
        );
    };

    const preferredDealerButtonContainer = (
        vehicle: VehicleOptions,
        index: number
    ) => {
        return (
            <div className="preferred-dealer-button-container">
                <div>
                    <NavLink
                        to={findPathByAlias('PreferredDealerView')}
                        onClick={() => {
                            handleVehicleChoice(vehicle);
                            sessionStorage.setItem(
                                VEHICLE_CARD_STORAGE_KEY,
                                JSON.stringify({
                                    vin: vehicle.vin,
                                })
                            );
                            closeOrCancelModal();
                        }}
                        className={`fmc-button ${
                            vehicle.preferredDealerCode
                                ? 'edit-preferred-dealer'
                                : ''
                        }`}
                        aria-label={
                            vehicle.preferredDealerCode
                                ? props.preferredDealerModalContent
                                      ?.btnEditAriaLabel
                                : props.preferredDealerModalContent
                                      ?.btnSelectAriaLabel
                        }
                        data-testid={`preferred-dealer-modal-select-button-${index}`}
                    >
                        <span className="secondary-button-text">
                            {vehicle.preferredDealerCode
                                ? props.preferredDealerModalContent?.btnEdit
                                : props.preferredDealerModalContent?.btnSelect}
                        </span>
                        {isLincoln && (
                            <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
                        )}
                    </NavLink>
                </div>
            </div>
        );
    };

    const modalContent = () => (
        <>
            {preferredDealerModalHeader()}
            {vehicleData?.length > 0 &&
                vehicleData.map((vehicle, index) => {
                    if (vehicle.vin) {
                        return (
                            <div
                                className={`vehicle-listing${
                                    index > 0 ? ' multiple-vehicle' : ''
                                }`}
                                key={`${index}-${vehicle.vin}`}
                            >
                                <div className="vehicle-info">
                                    {preferredDealerVehicleInfo(vehicle, index)}
                                    {vehicle.dealerInfoError && (
                                        <p
                                            className="fmc-type--content2"
                                            data-testid={`preferred-dealer-modal-error-${index}`}
                                        >
                                            {vehicle.dealerInfoError}
                                        </p>
                                    )}
                                    {vehicle.noDealerSelectedText &&
                                    !vehicle.dealerInfoError ? (
                                        <p
                                            className="fmc-type--content2"
                                            data-testid={`preferred-dealer-modal-nodealer-${index}`}
                                        >
                                            {vehicle.noDealerSelectedText}
                                        </p>
                                    ) : null}
                                    {!hideDealerInfo &&
                                    !vehicle.dealerInfoError &&
                                    !vehicle.noDealerSelectedText
                                        ? preferredDealerPersonalInfo(
                                              vehicle,
                                              index
                                          )
                                        : null}
                                </div>
                                {preferredDealerButtonContainer(vehicle, index)}
                            </div>
                        );
                    }
                })}
        </>
    );

    const preferredDealerModalProps: ModalContextProps = {
        modalType: {
            name: 'preferred-dealer-modal',
            primaryButtonLabel: '',
            secondaryButtonLabel: props.preferredDealerModalContent?.btnCancel,
            onPrimaryButtonClick: closeOrCancelModal,
            onSecondaryButtonClick: closeOrCancelModal,
            onAfterClose: closeOrCancelModal,
            children: modalContent(),
        },
    };

    useEffect(() => {
        if (
            props.preferredDealerModalContent &&
            props.isVisible &&
            vehicleData?.length > 0
        ) {
            setContext(preferredDealerModalProps);
        }
    }, [props.isVisible, vehicleData?.length]);

    return <></>;
};

export default PreferredDealerModal;
