import HttpService from '@services/http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { PreferredDealerResponse } from '@models/preferred-dealer';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import axios from 'axios';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface DealerResultsParameters {
    make: string;
    dealerName?: string;
    city?: string;
    state?: string;
    province?: string;
    postalCode?: string;
    isCanada?: boolean;
    latitude?: string;
    longitude?: string;
    paCode?: string;
}

export default class PreferredDealerService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private url = DSL_BASE_URL + DSL_API_PATH.PREFERRED_DEALER;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public getPreferredDealerResults = async (
        requestParameters: DealerResultsParameters
    ): Promise<PreferredDealerResponse> => {
        let response: any;
        try {
            response = await this.httpService.get(`${this.url}`, true, {
                proxy: false,
                httpAgent: proxyAgent,
                params: { ...requestParameters, maxDealers: 25 },
            });
        } catch (error) {
            console.error('Error fetching preferred dealer results:', error);
            return error?.response?.data;
        }
        return response?.data;
    };

    public updatePreferredDealer = async (
        vin: string,
        preferredDealer: string
    ) => {
        const dslUrl = this.appConfig.getAppConfiguration().dslUrl
            ? this.appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.ADD_VEHICLE;

        return axios
            .put<any>(
                url,
                { preferredDealer },
                {
                    headers: {
                        vin,
                        ...HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((e) => e.response.data);
    };
}
