import { useContext, useEffect, useState } from 'react';

import { getNameFromContent } from '@services/content-service/content-service-util';
import ServerSideService from '@services/server-side-service/server-side-service';
import ServerContext from '@contexts/serverContext';
import {
    ContentResponse,
    ExperienceFragmentModel,
} from '@services/content-service/content-service.interface';
import contentService from '@services/content-service/content-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

type ReturnType = [ContentResponse | null, (title: string) => string | boolean];

const appConfig = new AppConfigurationService();

const setRegionLanguageDefaults = (serverContext: any) => {
    if (!serverContext.currentRegionCode)
        serverContext.currentRegionCode =
            appConfig.getAppConfiguration().countryCode;

    if (!serverContext.currentLanguageRegionCode)
        serverContext.currentLanguageRegionCode =
            appConfig.getAppConfiguration().languageRegionCode;
    if (!serverContext.brand)
        serverContext.brand = appConfig.getAppConfiguration().brand;
};

const initializeContent = (content: any, keys: string[]) => {
    let current = content;
    for (const key of keys) {
        if (!current[key]) {
            current[key] = {};
        }
        current = current[key];
    }
};

const buildServerContext = (
    serverContext: any,
    category: string,
    name: string,
    brand?: string,
    componentName?: string
) => {
    const [currentRegionCode, currentLanguageRegionCode] = [
        serverContext.currentRegionCode,
        serverContext.currentLanguageRegionCode,
    ];

    if (brand) {
        initializeContent(serverContext.content, [
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            category,
            name,
        ]);
        if (componentName) {
            initializeContent(
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name],
                [componentName]
            );
        }
    } else {
        initializeContent(serverContext.content, [
            currentRegionCode,
            currentLanguageRegionCode,
            category,
            name,
        ]);
        if (componentName) {
            initializeContent(
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name],
                [componentName]
            );
        }
    }
};

const getContentFromCache = (serverContext: any, params: ContentParams) => {
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        category,
        name,
    } = params;

    const contentPath = brand
        ? serverContext.content[brand]?.[currentRegionCode]?.[
              currentLanguageRegionCode
          ]?.[category]?.[name]
        : serverContext.content[currentRegionCode]?.[
              currentLanguageRegionCode
          ]?.[category]?.[name];

    return contentPath && Object.keys(contentPath).length !== 0
        ? contentPath
        : null;
};

interface ContentParams {
    brand: string;
    currentRegionCode: string;
    currentLanguageRegionCode: string;
    category: string;
    name: string;
}

const storeContentInContext = (
    serverContext: any,
    response: any,
    params: ContentParams
) => {
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        category,
        name,
    } = params;

    if (brand) {
        serverContext.content[brand][currentRegionCode][
            currentLanguageRegionCode
        ][category][name] = response;
    } else {
        serverContext.content[currentRegionCode][currentLanguageRegionCode][
            category
        ][name] = response;
    }
};

const fetchContent = async (
    params: ContentParams & { ymmServlet?: boolean },
    serverContext: any,
    onSuccess: (data: any) => void,
    setRequested: (value: boolean) => void
) => {
    const {
        category,
        name,
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        ymmServlet,
    } = params;

    const promise = contentService.getContent(
        category,
        name,
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        ymmServlet
    );

    if (ServerSideService.isServerSide()) {
        serverContext.promises.push(promise);
    }

    try {
        const response = await promise;

        if (ServerSideService.isServerSide()) {
            storeContentInContext(serverContext, response, params);
        }

        onSuccess(response);
    } catch (err) {
        if (ServerSideService.isServerSide()) {
            console.log(
                `error resolving promise for category:${category} name:${name}. ${err}`
            );
        }
    }

    setRequested(true);
};

export function useContent(
    category: string,
    name: string,
    brandOverride?: string,
    ymmServlet?: boolean
): ReturnType {
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();

    if (ServerSideService.isClientSide()) {
        serverContext.currentRegionCode = appConfig.get2LetterCountryCode();
        serverContext.currentLanguageRegionCode =
            appConfig.getLanguageRegionCode();
    }
    setRegionLanguageDefaults(serverContext);

    const brand = brandOverride ? brandOverride : serverContext.brand;
    const currentRegionCode = serverContext.currentRegionCode;
    const currentLanguageRegionCode = serverContext.currentLanguageRegionCode;

    const [content, setContent] = useState<ContentResponse | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);

    const loadContent = async () => {
        if (content) return;

        const contentParams: ContentParams = {
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            category,
            name,
        };

        buildServerContext(serverContext, category, name, brand);

        const cachedContent = getContentFromCache(serverContext, contentParams);
        if (cachedContent) {
            setContent(cachedContent);
            return;
        }

        if (!contentRequested) {
            await fetchContent(
                { ...contentParams, ymmServlet },
                serverContext,
                setContent,
                setContentRequested
            );
        }
    };

    useEffect(() => {
        if (!content) {
            loadContent();
        }
    }, [content]);

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
    }, [category, name, brandOverride, ymmServlet]);

    function getValueByName(name: string): string | boolean {
        if (content) return getNameFromContent(content, name);
        return '';
    }

    if (content && content.name === 'resource-not-found') {
        return [null, getValueByName];
    }

    return [content, getValueByName];
}

const getExperienceContentFromCache = (
    serverContext: any,
    params: ContentPathParams
) => {
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        category,
        name,
        componentName,
    } = params;

    const contentPath = brand
        ? serverContext.content[brand]?.[currentRegionCode]?.[
              currentLanguageRegionCode
          ]?.[category]?.[name]?.[componentName]
        : serverContext.content[currentRegionCode]?.[
              currentLanguageRegionCode
          ]?.[category]?.[name]?.[componentName];

    return contentPath && Object.keys(contentPath).length !== 0
        ? contentPath
        : null;
};

interface ContentPathParams {
    brand: string;
    currentRegionCode: string;
    currentLanguageRegionCode: string;
    category: string;
    name: string;
    componentName: string;
}

interface FetchParams extends ContentPathParams {
    parentFolder: string;
    ymmServlet?: boolean;
}

const storeInServerContext = (
    serverContext: any,
    response: any,
    params: ContentPathParams
) => {
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        category,
        name,
        componentName,
    } = params;

    if (brand) {
        serverContext.content[brand][currentRegionCode][
            currentLanguageRegionCode
        ][category][name][componentName] = response;
    } else {
        serverContext.content[currentRegionCode][currentLanguageRegionCode][
            category
        ][name][componentName] = response;
    }
};

const fetchExperienceContent = async <T,>(
    params: FetchParams,
    serverContext: any,
    onSuccess: (data: T) => void
) => {
    const {
        category,
        name,
        componentName,
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        ymmServlet,
        parentFolder,
    } = params;

    const promise = contentService.getExperience<T>(
        category,
        name,
        componentName,
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        ymmServlet,
        parentFolder
    );

    if (ServerSideService.isServerSide()) {
        serverContext.promises.push(promise);
    }

    try {
        const response = await promise;

        if (ServerSideService.isServerSide()) {
            storeInServerContext(serverContext, response, params);
        }

        onSuccess(response);
    } catch (err) {
        if (ServerSideService.isServerSide()) {
            console.log(
                `error resolving promise for ${
                    brand ? `brand:${brand}` : ''
                } category:${category} name:${name} componentName:${componentName}. ${err}`
            );
        }
    }
};

export function useExperienceContent<T extends ExperienceFragmentModel>(
    category: string,
    name: string,
    componentName: string,
    brandOverride?: string,
    ymmServlet?: boolean,
    parentFolderOverride?: string
) {
    const serverContext = useContext(ServerContext);
    setRegionLanguageDefaults(serverContext);

    const brand = brandOverride !== '' ? serverContext.brand : '';
    const currentRegionCode = serverContext.currentRegionCode;
    const currentLanguageRegionCode = serverContext.currentLanguageRegionCode;
    const parentFolder = parentFolderOverride || 'global-account';

    const [content, setContent] = useState<T | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);

    const loadContent = async () => {
        if (content) return;

        const pathParams: ContentPathParams = {
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            category,
            name,
            componentName,
        };

        buildServerContext(serverContext, category, name, brand, componentName);

        const cachedContent = getExperienceContentFromCache(
            serverContext,
            pathParams
        );
        if (cachedContent) {
            setContent(cachedContent);
            return;
        }

        if (!contentRequested && category && name && componentName) {
            const fetchParams: FetchParams = {
                ...pathParams,
                parentFolder,
                ymmServlet,
            };

            await fetchExperienceContent<T>(
                fetchParams,
                serverContext,
                setContent
            );
            setContentRequested(true);
        }
    };

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
        loadContent();
    }, [category, name, componentName, brandOverride, ymmServlet]);

    return [content];
}
