import React, { ReactNode } from 'react';
import { PageType } from '@sections/view-template/page-type';
import { SmartTileContent } from '@models/experiencefragments/smarttiles';
import SmartTile from '../smart-tile/smart-tile';
import './smart-tile-account.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export type HeaderType = 'good' | 'moderate' | 'severe' | 'none';
export interface SmartTileProps extends SmartTileContent {
    header: string;
    ctaHref: string;
    ctaTitle: string;
    headerType?: HeaderType;
    children?: ReactNode;
    className?: string;
    pageType?: PageType;
    analyticEventTitle?: string;
    analyticEventName?: string;
    imageUrl: string;
    displayModal?: () => void;
    isMobile?: boolean;
    vehicleCount?: number;
    setVehicleCount?: any;
    openAddVehicleModal: boolean;
}
const SmartTileAccount = (props: SmartTileProps) => {
    const {
        dynamicTitle,
        dynamicCTATitle,
        analyticEventName,
        iconPath,
        staticSubCopy,
    } = props;
    const appConfig = new AppConfigurationService();
    const isFord: boolean = appConfig.brand === 'ford';

    return (
        <SmartTile
            {...props}
            className="smart-tile-account"
            header={dynamicTitle}
            ctaTitle={dynamicCTATitle}
            analyticEventName={analyticEventName}
        >
            <img
                className="icon"
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
                width={'24'}
                height={'24'}
                loading={'lazy'}
                fetchPriority="low"
            />

            {isFord ? (
                <div className="smart-tile__content--container">
                    <div className="smart-tile__text--container">
                        <h3 className={`fmc-type--subtitle1`}>
                            {dynamicTitle ? dynamicTitle : ''}
                        </h3>
                        <h3 className={`fmc-type--heading6`}>
                            {dynamicTitle ? dynamicTitle : ''}
                        </h3>
                    </div>
                    <img
                        src={'./icons/smart-tile-caret--right.svg'}
                        alt=""
                        height={'24'}
                        width={'9'}
                    />
                </div>
            ) : (
                <>
                    <h3
                        className={`fmc-type--subtitle1 fds-color__text--primary`}
                    >
                        {dynamicTitle ? dynamicTitle : ''}
                    </h3>
                    <h3
                        className={`fmc-type--heading6 fds-color__text--primary`}
                    >
                        {dynamicTitle ? dynamicTitle : ''}
                    </h3>

                    <div className="fmc-type--body1">{staticSubCopy}</div>
                </>
            )}
        </SmartTile>
    );
};
export default SmartTileAccount;
