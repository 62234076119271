import React, { useEffect, useRef, useState } from 'react';
import './secondary-nav.scss';
import useSecondaryNavContent from '@sections/secondary-nav/hook/use-secondary-nav-content';
import RightCaret from '@sections/secondary-nav/components/right-caret';
import { useWindowSize } from '@hooks/use-window-size';
import { TABLET_BREAKPOINT } from '@services/support-constants';
import { KEYBOARD_KEYS } from '@constants';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';
import UserAccountTile from '@sections/secondary-nav/components/user-account-tile/user-account-tile';
import serverSideService from '@services/server-side-service/server-side-service';
import { useAnalytics } from '@/hooks/use-analytics';

interface NavListItems {
    category: string;
    connected: boolean;
    isConnected: boolean;
    link: string;
    title: string;
}

export interface SecondaryNavContent {
    ':type': string;
    navList: NavListItems[];
    profileName: string;
    shortcutsLabel: string;
    showSecondaryNav: boolean;
    viewAllLabel: string;
}

const SecondaryNav = () => {
    const secondaryNavRef = useRef(null);
    const expandedContainerRef = useRef(null);
    const secondaryBlue = '#0562d2';
    const secondaryNavContent: SecondaryNavContent = useSecondaryNavContent();
    const [fireAnalytics] = useAnalytics();
    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [groupedItems, setGroupedItems] = useState<Record<
        string,
        NavListItems[]
    > | null>(null);
    const { connectedStatusContext } = useConnectedStatusContext();
    const isConnectedHasValue =
        typeof connectedStatusContext.isConnected === 'boolean';

    const mapConnectedLinks = (items: NavListItems[]): NavListItems[] => {
        return items.map((item) => {
            if (item?.connected) {
                return {
                    ...item,
                    link: item?.link?.concat(
                        `?vin=${connectedStatusContext.vin}`
                    ),
                };
            } else if (item?.link?.includes(':vin')) {
                return {
                    ...item,
                    link: item?.link?.replace(
                        ':vin',
                        connectedStatusContext.vin
                    ),
                };
            }

            return item;
        });
    };

    const generateConnectedClass = (): string => {
        return connectedStatusContext.isConnected
            ? 'is-connected'
            : 'not-connected';
    };

    const generateExpandedClass = (): string => {
        return isExpanded ? 'is-expanded' : '';
    };

    const transformedFirstName = (): string => {
        if (connectedStatusContext?.firstName) {
            return secondaryNavContent?.profileName.replace(
                '##firstName##',
                connectedStatusContext.firstName
            );
        }

        return null;
    };

    const groupItemsIntoCategories = (items: NavListItems[]) => {
        if (!connectedStatusContext.isConnected) {
            items = items.filter(
                (item) =>
                    (!item?.isConnected && !item?.link?.includes(':vin')) ||
                    item?.link?.includes('discover-your-ford')
            );
        }

        if (!connectedStatusContext.hasVehicles) {
            items = items.filter(
                (item) => !item?.link?.includes('discover-your-ford')
            );
        }

        return mapConnectedLinks(items)?.reduce((acc, item) => {
            if (!acc[item?.category]) {
                acc[item?.category] = [];
            }
            acc[item?.category].push(item);
            return acc;
        }, {} as Record<string, NavListItems[]>);
    };

    const handleClickOutsideSecondaryNav = (event: MouseEvent) => {
        const mobileScenario =
            isMobile &&
            expandedContainerRef.current &&
            !expandedContainerRef.current.contains(event.target);
        const desktopScenario =
            !isMobile &&
            secondaryNavRef.current &&
            !secondaryNavRef.current.contains(event.target);

        if (desktopScenario || mobileScenario) {
            setIsExpanded(false);
        }
    };

    const handleTabOnLastLink = (
        e: React.KeyboardEvent<HTMLAnchorElement>,
        index: number,
        itemIndex: number,
        category: string
    ) => {
        if (
            e.key === KEYBOARD_KEYS.TAB &&
            index === Object.keys(groupedItems).length - 1 &&
            itemIndex === groupedItems[category].length - 1
        ) {
            setIsExpanded(false);
        }
    };

    const monitorScroll = () => {
        const syndicatedHeader: HTMLDivElement = document.querySelector(
            'header.fgx-pantry-bootstrap'
        );

        if (window.scrollY >= 56 && syndicatedHeader) {
            syndicatedHeader.style.opacity = '0.001';
        } else {
            syndicatedHeader.style.opacity = '1';
        }
    };

    useEffect(() => {
        if (secondaryNavContent && isConnectedHasValue) {
            setGroupedItems(
                groupItemsIntoCategories(secondaryNavContent.navList)
            );
        }
    }, [secondaryNavContent, connectedStatusContext]);

    useEffect(() => {
        if (size.width >= TABLET_BREAKPOINT) setIsMobile(false);
        if (size.width < TABLET_BREAKPOINT) setIsMobile(true);
    }, [size.width]);

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            if (isMobile && isExpanded) {
                document.body.classList.add('subnav-open');
            } else {
                document.body.classList.remove('subnav-open');
            }
        }
    }, [isExpanded]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideSecondaryNav);
        document.addEventListener('scroll', monitorScroll);

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideSecondaryNav
            );
            document.removeEventListener('scroll', monitorScroll);
        };
    }, []);

    return (
        <div
            className={`secondary-nav__container ${generateExpandedClass()}`}
            data-testid="secondary-nav-container"
            ref={secondaryNavRef}
        >
            <div className="secondary-nav__content">
                <button
                    className="secondary-nav__clickable-bar"
                    tabIndex={0}
                    onClick={() => {
                        if (!isExpanded) {
                            fireAnalytics('secondaryNavOnclickEvent');
                        }
                        setIsExpanded(!isExpanded);
                    }}
                    aria-label="Toggle Shortcuts Navigation"
                    data-testid="secondary-nav-clickable-bar"
                >
                    <div className="header-row">
                        <p
                            className="my-shortcuts-label"
                            data-testid="my-shortcuts-label"
                        >
                            {secondaryNavContent?.shortcutsLabel}
                        </p>

                        <div
                            className={`view-all__toggle-container ${generateExpandedClass()}`}
                        >
                            <span
                                className="view-all__label"
                                data-testid="view-all-label"
                            >
                                {secondaryNavContent?.viewAllLabel}
                            </span>

                            <RightCaret color={secondaryBlue} />
                        </div>
                    </div>
                </button>

                <div
                    className={`expanded-container ${generateExpandedClass()}`}
                    data-testid={
                        isExpanded
                            ? 'expanded-container-open'
                            : 'expanded-container-closed'
                    }
                    ref={expandedContainerRef}
                >
                    {isMobile && !!transformedFirstName() ? (
                        <UserAccountTile
                            firstName={transformedFirstName()}
                            onClick={() => setIsExpanded(false)}
                        />
                    ) : null}

                    <div
                        className={`expanded-content ${generateConnectedClass()}`}
                    >
                        {groupedItems &&
                            Object.keys(groupedItems).map(
                                (category: string, index: number) =>
                                    category &&
                                    category != 'undefined' &&
                                    category != 'null' ? (
                                        <div
                                            className="expanded-content__group"
                                            key={category}
                                        >
                                            <h3 className="expanded-content__group--heading">
                                                {category}
                                            </h3>
                                            {groupedItems[category].map(
                                                (
                                                    item: NavListItems,
                                                    itemIndex: number
                                                ) =>
                                                    item.link && item.title ? (
                                                        <div
                                                            className="expanded-content__group--item"
                                                            data-testid={`expanded-content-item`}
                                                            key={
                                                                item?.title ||
                                                                ''
                                                            }
                                                        >
                                                            <a
                                                                href={
                                                                    item?.link
                                                                }
                                                                onClick={() => {
                                                                    const secondaryNavCategoryName =
                                                                        category ||
                                                                        '';
                                                                    const secondaryNavLinkName =
                                                                        item?.title ||
                                                                        '';
                                                                    fireAnalytics(
                                                                        'secondaryNavSelectionOnclickEvent',
                                                                        '',
                                                                        {
                                                                            secondaryNavCategoryName,
                                                                            secondaryNavLinkName,
                                                                        }
                                                                    );
                                                                }}
                                                                tabIndex={
                                                                    isExpanded
                                                                        ? 0
                                                                        : -1
                                                                }
                                                                onKeyDown={(
                                                                    e
                                                                ) =>
                                                                    handleTabOnLastLink(
                                                                        e,
                                                                        index,
                                                                        itemIndex,
                                                                        category
                                                                    )
                                                                }
                                                            >
                                                                {item?.title ||
                                                                    ''}
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                            )}
                                            {isMobile &&
                                            index !==
                                                Object.keys(groupedItems)
                                                    .length -
                                                    1 ? (
                                                <hr className="expanded-content__group--divider" />
                                            ) : null}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondaryNav;
