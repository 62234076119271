import { lazy } from 'react';

const PageNotFoundView = lazy(
    () => import('./page-not-found-view/page-not-found-view')
);
const AccountDashboardView = lazy(
    () => import('./account-dashboard-view/account-dashboard-view')
);
const AccountSettingsView = lazy(
    () => import('./account-settings-view/account-settings-view')
);
const CommunicationPreferencesView = lazy(
    () =>
        import(
            './communication-preferences-view/communication-preferences-view'
        )
);
const EmailChannelPreferenceView = lazy(
    () =>
        import(
            './email-channel-preferences-view/email-channel-preferences-view'
        )
);
const ChangeEmailView = lazy(
    () => import('./change-email-view/change-email-view')
);
const WalletView = lazy(() => import('./wallet-view/wallet-view'));
const MarketingOptionsView = lazy(
    () => import('./marketing-options-view/marketing-options-view')
);
const DeleteAccountView = lazy(
    () => import('./delete-account-view/delete-account-view')
);
const OrderDetailsView = lazy(
    () => import('./order-details-view/order-details-view')
);
const OrderHistoryView = lazy(
    () => import('./order-history-view/order-history-view')
);
const PreferredDealerView = lazy(
    () => import('./preferred-dealer-view/preferred-dealer-view')
);
const VehicleOrderTrackingView = lazy(
    () => import('./vehicle-order-tracking-view/vehicle-order-tracking-view')
);
const VehicleOrderTrackingStatusView = lazy(
    () =>
        import(
            '@views/vehicle-order-tracking-status-view/vehicle-order-tracking-status-view'
        )
);
const CreateReturnView = lazy(
    () => import('./create-return-view/create-return-view')
);
const CommunicationCenterView = lazy(
    () => import('./communication-center-view/communication-center-view')
);

export {
    PageNotFoundView,
    AccountDashboardView,
    AccountSettingsView,
    CommunicationPreferencesView,
    EmailChannelPreferenceView,
    ChangeEmailView,
    WalletView,
    MarketingOptionsView,
    DeleteAccountView,
    OrderDetailsView,
    OrderHistoryView,
    PreferredDealerView,
    VehicleOrderTrackingView,
    VehicleOrderTrackingStatusView,
    CreateReturnView,
    CommunicationCenterView,
};
