import React from 'react';

interface PlusSignIconProps {
    color: string;
}

const PlusSignIcon = (props: PlusSignIconProps) => {
    const { color } = props;

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.6004 11.3999H12.6004V5.3999H11.4004V11.3999H5.40039V12.5999H11.4004V18.5999H12.6004V12.5999H18.6004V11.3999Z"
                fill={color}
                data-testid="plus-sign-icon-path"
            />
        </svg>
    );
};

export default PlusSignIcon;
