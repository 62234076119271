import ServerSideService from '../server-side-service/server-side-service';

export default class MicrosoftService {
    private win: any;

    constructor() {
        if (ServerSideService.isClientSide()) {
            this.win = window;
        }
    }

    public getMapsObject = (): any | undefined => {
        return this.win?.Microsoft?.Maps;
    };

    public getMicrosoftLocation = (lat: number, long: number): any => {
        return this.win?.Microsoft?.Maps?.Location?.parseLatLong(
            `${lat},${long}`
        );
    };
}
