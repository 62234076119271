import React, { ReactNode, Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { configs } from './router-configuration';
import ServerContext from '@contexts/serverContext';
import {
    findRoute,
    findRouteByAlias,
    hasBrandAndLocale,
    mergeRoutes,
} from '@utils/router-util/router-util';
import { DEFAULT_ROUTES } from './default-routes';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';

const appConfig = new AppConfigurationService();

export interface GlobalAccountRoute {
    path: string | string[];
    exact?: boolean;
    component?: ReactNode;
    alias: string;
}

export const RoutesList = () => {
    DEFAULT_ROUTES.forEach(Object.freeze);
    configs.forEach(Object.freeze);
    const serverContext = useContext(ServerContext);
    const routesList = mergeRoutes(
        DEFAULT_ROUTES,
        configs,
        serverContext.brand || appConfig.brand,
        serverContext.currentLanguageRegionCode ||
            appConfig.currentLanguageRegionCode
    );

    const routes = routesList.map((route) => {
        const { component, path } = route;
        const modifiedRoute = {
            ...route,
            component,
            path: Array.isArray(path) ? path.join('') : path,
        };
        serverContext.pageNotFound = false;

        if (
            hasBrandAndLocale(appConfig) &&
            path !== '/' &&
            path !== appConfig.currentRoot
        ) {
            modifiedRoute.path = appConfig.currentRoot + path;
            modifiedRoute.path = modifiedRoute.path.replace(/\/\//, '/');
        }

        return (
            <Route
                key={modifiedRoute.path}
                element={modifiedRoute.component}
                index={modifiedRoute.path === '/'}
                {...modifiedRoute}
            />
        );
    });

    return (
        <Suspense fallback={<ActivityIndicator className="full-height" />}>
            <Routes>{routes}</Routes>
        </Suspense>
    );
};

export const findPathByAlias = (alias: string, id?: string) => {
    const route = findRoute(
        findRouteByAlias,
        DEFAULT_ROUTES,
        configs,
        appConfig.brand
    );
    // TO DO: Refactor naming of variables
    const actualRoute = route(alias);
    const brandAndLocale = `/${appConfig.brand}/${appConfig.currentLanguageRegionCode}`;

    if (id) {
        return hasBrandAndLocale(appConfig)
            ? brandAndLocale + actualRoute + '/' + id
            : actualRoute + '/' + id;
    }

    return hasBrandAndLocale(appConfig)
        ? brandAndLocale + actualRoute
        : actualRoute;
};
