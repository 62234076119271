import React, { ReactNode } from 'react';

import { SeoConfig } from '@services/seo-service/seo-service';

import { SearchBar, ViewBanner } from '..';
import { PageType } from './page-type';
import {
    DynamicMastheadGreetingStyle,
    DynamicMastheadProperties,
} from '../account-portal/components/vehicle-tabs/hooks/use-dynamic-masthead-content';

import './view-template.scss';

export interface Props {
    page: PageType;
    seoConfig?: SeoConfig;
    children?: ReactNode;
    ctaTopComponent?: ReactNode;
    ctaBottomComponent?: ReactNode;
    backgroundImageDesktop?: string;
    backgroundImageMobile?: string;
    breadcrumb?: string;
    hideInformationGridSection?: boolean;
    hideSearchBar?: boolean;
    className?: string;
    topMasthead?: ReactNode;
    carousel?: ReactNode;
    hasCarousel?: boolean;
    hasVehicles?: boolean;
    hasOrders?: boolean;
    dynamicMastheadContent?: DynamicMastheadGreetingStyle;
    dynamicMastheadProperties?: DynamicMastheadProperties;
}

const ViewTemplate = (props: Props) => {
    const noCarouselForMarket: string = !props.hasCarousel
        ? 'no-carousel-market'
        : '';
    const hasNoVehicles: string =
        props.hasVehicles || props.hasOrders ? 'has-vehicles' : 'no-vehicles';

    return (
        <div
            className={`shared-view ${hasNoVehicles} ${props.className} ${noCarouselForMarket}`}
            data-testid={'view-template-container'}
        >
            <div id="Hero" className={'limit-1440'}>
                <ViewBanner
                    topMasthead={props.topMasthead}
                    topComponent={props.ctaTopComponent}
                    bottomComponent={props.ctaBottomComponent}
                    backgroundImageDesktop={props.backgroundImageDesktop}
                    backgroundImageMobile={props.backgroundImageMobile || ''}
                    hasVehicles={!hasNoVehicles}
                    dynamicMastheadProperties={props.dynamicMastheadProperties}
                />
            </div>

            {props.carousel}
            {props.children}
            {!props.hideSearchBar && (
                <div className={'limit-1440'}>
                    <SearchBar />
                </div>
            )}
        </div>
    );
};

export default ViewTemplate;
