import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SmartTile, { SmartTileProps } from '../smart-tile/smart-tile';

import './smart-tile-connected-services.scss';
import ConnectedServicesModal from './connected-services-modal';
import ProfileService from '@services/profile-service/profile-service';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { ConnectedServicesModalContentProps } from './hook/use-connected-services-modal-content';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import { VehicleAttributes } from '@models/vehicle-attributes';
import SubscriptionsService from '@services/subscription-service/subscription-service';
import HttpService from '@services/http-service/http-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    buildCampaignIdQueryParameter,
    buildConnectedServicesExtraQueryParams,
} from '@utils/router-util/router-util';
import { useLocation } from 'react-router-dom';

interface Props {
    displayAsModal?: boolean;
    hasVehicles?: boolean;
    vehiclesCount: number;
    analyticEventName: string;
    ctaTitle: string;
    updateAddVehicleStatusWithVin: () => void;
    useAddVehicleContent: () => void;
    setIsLoading?: any;
    addVehicleContent: AddVehicleContent;
    connectedServicesModalContent: ConnectedServicesModalContentProps;
    fromConnectedServicesParam: boolean;
    connectedServicesClassName?: string;
    garageVehicles: VehicleAttributes[];
    openAddVehicleModal: boolean;
    handleAddVehicleModal?: Dispatch<SetStateAction<boolean>>;
    setIsNewVehicleAdded: Dispatch<SetStateAction<boolean>>;
    setTeslaBannerNotificationStatus?: Dispatch<SetStateAction<boolean>>;
}

export interface SubscriptionCounts {
    paidSubscriptions: number;
    paidSubscriptionsExpiring: number;
    freeSubscriptions: number;
    freeSubscriptionsExpiring: number;
}

export interface SubscriptionCountsWithVIN {
    vin: string;
    paidSubscriptions: number;
    paidSubscriptionsExpiring: number;
    freeSubscriptions: number;
    freeSubscriptionsExpiring: number;
}

const SmartTileConnectedServices = (props: Props & SmartTileProps) => {
    const {
        vehiclesCount,
        dynamicTitle,
        staticCTATitle,
        dynamicCTATitle,
        hasVehicles,
        addVehicleContent,
        analyticEventName,
        iconPath,
        staticSubCopy,
        activeSubscriptionsText,
        expiringSubscriptionsText,
        setIsLoading,
        garageVehicles,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [profileData, setProfileData] = useState<any>(null);
    const [subscriptionCountsWithVINList, setSubscriptionCountsWithVINList] =
        useState<SubscriptionCountsWithVIN[]>([]);
    const [activeSubscriptionCount, setActiveSubscriptionCount] =
        useState<number>(0);
    const [expiringSubscriptionCount, setExpiringSubscriptionCount] =
        useState<number>(0);
    const profileService = new ProfileService();
    const authenticationService = new AuthenticationService();
    const subscriptionService = new SubscriptionsService(HttpService);
    const displayStaticSubCopy =
        profileData?.vehicles.length !== 1 ||
        ((!activeSubscriptionCount || !activeSubscriptionsText) &&
            (!expiringSubscriptionCount || !expiringSubscriptionsText));
    const queryParams = new URLSearchParams(useLocation().search);

    const getProfileData = () => {
        profileService.request().then((profile) => {
            if (profile) setProfileData(profile);
            else setProfileData(null);
        });
    };

    const getSubscriptionCounts = () => {
        garageVehicles?.forEach((vehicle) => {
            subscriptionService.getSubscriptions(vehicle.vin).then((data) => {
                if (data) {
                    if (garageVehicles.length > 1) {
                        setSubscriptionCountsWithVINList((prevState) => [
                            ...prevState,
                            { vin: vehicle.vin, ...data },
                        ]);
                    } else {
                        setActiveSubscriptionCount(
                            data.paidSubscriptions + data.freeSubscriptions
                        );
                        setExpiringSubscriptionCount(
                            data.paidSubscriptionsExpiring +
                                data.freeSubscriptionsExpiring
                        );
                    }
                }
            });
        });
    };

    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                getProfileData();
            }
        });
    }, [vehiclesCount]);

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                setActiveSubscriptionCount(0);
                setExpiringSubscriptionCount(0);
                setSubscriptionCountsWithVINList([]);
                getSubscriptionCounts();
            }
        });
    }, [garageVehicles]);

    const vehicleData = profileData?.vehicles.map((data) => {
        const { vin } = data;
        return {
            ctaHref: `?vin=${vin}${buildCampaignIdQueryParameter()}${buildConnectedServicesExtraQueryParams(
                queryParams
            )}`,
        };
    });

    const displayModal = () => {
        vehicleData?.length !== 1 && setIsModalVisible(true);
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };
    const ctaHref = (): string | null => {
        return vehicleData?.length === 1 ? vehicleData[0].ctaHref : null;
    };

    useEffect(() => {
        if (
            props.fromConnectedServicesParam &&
            props.currentVehiclesCount > 1
        ) {
            displayModal();
        }
    }, [props.fromConnectedServicesParam]);

    return (
        <SmartTile
            {...props}
            className={`smart-tile-connected-services ${
                isModalVisible ? '' : 'toggle-off-hover'
            }`}
            connectedServicesClassName="connected-services-tile-cta"
            header={dynamicTitle || ''}
            ctaTitle={hasVehicles ? staticCTATitle : dynamicCTATitle}
            ctaHref={ctaHref()}
            displayModal={() => {
                displayModal();
            }}
            analyticEventTitle="Connected Services"
            analyticEventName={analyticEventName}
            displayAddVehicleModal={!hasVehicles}
            isModalOpen={isModalVisible}
            addVehicleContent={addVehicleContent}
            setIsLoading={setIsLoading}
            currentVehiclesCount={props.currentVehiclesCount}
            setCurrentVehiclesCount={props.setCurrentVehiclesCount}
            vehiclesOnOrder={props.vehiclesOnOrder}
            garageVehicles={garageVehicles}
            openAddVehicleModal={props.openAddVehicleModal}
            handleAddVehicleModal={props.handleAddVehicleModal}
            setIsNewVehicleAdded={props.setIsNewVehicleAdded}
            setTeslaBannerNotificationStatus={
                props.setTeslaBannerNotificationStatus
            }
        >
            <img
                className="icon"
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
                width={'24'}
                height={'24'}
                loading={'lazy'}
                fetchPriority="low"
            />
            {isFord ? (
                <div className="smart-tile__content--container">
                    <div className="smart-tile__text--container">
                        <h6 className={`fmc-type--subtitle1`}>
                            {dynamicTitle ? dynamicTitle : ''}
                        </h6>
                        <h6 className={`fmc-type--heading6`}>
                            {dynamicTitle ? dynamicTitle : ''}
                        </h6>
                    </div>
                    <img
                        src={'./icons/smart-tile-caret--right.svg'}
                        alt=""
                        height={'24'}
                        width={'9'}
                    />
                    {props.connectedServicesModalContent && (
                        <ConnectedServicesModal
                            isVisible={isModalVisible}
                            onClose={closeModal}
                            {...props}
                            connectedServicesModalContent={
                                props.connectedServicesModalContent
                            }
                            subscriptionCountsWithVINList={
                                subscriptionCountsWithVINList
                            }
                        />
                    )}
                </div>
            ) : (
                <>
                    <h6 className={`fmc-type--subtitle1`}>
                        {dynamicTitle ? dynamicTitle : ''}
                    </h6>
                    <h6 className={`fmc-type--heading6`}>
                        {dynamicTitle ? dynamicTitle : ''}
                    </h6>
                    {displayStaticSubCopy ? (
                        <div
                            className="fmc-type--body1"
                            data-testid="connected-services-tile-static-subcopy"
                        >
                            {staticSubCopy}
                        </div>
                    ) : (
                        <div className="smart-tile-subscription-counts-container">
                            {activeSubscriptionCount &&
                            activeSubscriptionsText ? (
                                <div
                                    className="fmc-type--body1 smart-tile-subscription-count"
                                    data-testid="connected-services-tile-active-subscriptions"
                                >
                                    {activeSubscriptionCount +
                                        ' ' +
                                        activeSubscriptionsText}
                                </div>
                            ) : null}
                            {expiringSubscriptionCount &&
                            expiringSubscriptionsText ? (
                                <div
                                    className="fmc-type--body1 smart-tile-subscription-count"
                                    data-testid="connected-services-tile-expiring-subscriptions"
                                >
                                    {expiringSubscriptionCount +
                                        ' ' +
                                        expiringSubscriptionsText}
                                </div>
                            ) : null}
                        </div>
                    )}
                    {props.connectedServicesModalContent && (
                        <ConnectedServicesModal
                            isVisible={isModalVisible}
                            onClose={closeModal}
                            {...props}
                            connectedServicesModalContent={
                                props.connectedServicesModalContent
                            }
                            subscriptionCountsWithVINList={
                                subscriptionCountsWithVINList
                            }
                        />
                    )}
                </>
            )}
        </SmartTile>
    );
};

export default SmartTileConnectedServices;
