import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { EU_COUNTRIES } from '@constants';
import { PageType } from '@sections/view-template/page-type';
import { Link, SecondaryButton } from '@common/index';
import { SmartTileContent } from '@models/experiencefragments/smarttiles';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { AddVehicle } from '@sections/add-vehicle/add-vehicle';
import { useAnalytics } from '@hooks/use-analytics';
import { findPathByAlias } from '@routes/routesList';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import './smart-tile.scss';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleAttributes } from '@models/vehicle-attributes';

export type HeaderType = 'good' | 'moderate' | 'severe' | 'none';

export interface SmartTileProps extends SmartTileContent {
    header: string;
    ctaHref: string;
    ctaTitle: string;
    headerType?: HeaderType;
    children?: ReactNode;
    className?: string;
    pageType?: PageType;
    analyticEventTitle?: string;
    analyticEventName?: string;
    displayAddVehicleModal?: boolean;
    updateAddVehicleStatusWithVin?: () => void;
    displayModal?: () => void;
    addVehicleContent?: AddVehicleContent;
    isMobile?: boolean;
    isModalOpen?: boolean;
    setIsLoading?: any;
    currentVehiclesCount?: number;
    setCurrentVehiclesCount?: Dispatch<SetStateAction<number>>;
    connectedServicesClassName?: string;
    vehiclesOnOrder?: OrderedVehiclesItem[];
    garageVehicles?: VehicleAttributes[];
    activeSubscriptionsText?: string;
    expiringSubscriptionsText?: string;
    openAddVehicleModal: boolean;
    handleAddVehicleModal?: Dispatch<SetStateAction<boolean>>;
    setIsNewVehicleAdded?: Dispatch<SetStateAction<boolean>>;
    setTeslaBannerNotificationStatus?: Dispatch<SetStateAction<boolean>>;
}

const getInternalURL = (
    type: string,
    ctaHref: string,
    currentAppConfiguration: any
) => {
    let internalURL = null;
    let tileId = '';

    if (type === 'account-settings') {
        internalURL = findPathByAlias('AccountSettingsView');
        tileId = 'smart-tile-account-settings';
    } else if (type === 'communication-preference') {
        internalURL = EU_COUNTRIES.includes(currentAppConfiguration.countryCode)
            ? findPathByAlias('MarketingOptionsView')
            : findPathByAlias('CommunicationPreferenceView');
        tileId = 'smart-tile-communication-preference';
    } else if (type === 'wallet') {
        internalURL = findPathByAlias('WalletView');
        tileId = 'smart-tile-wallet';
    } else if (type === 'subscription' && ctaHref) {
        internalURL = findPathByAlias('ConnectedServicesView') + ctaHref;
        tileId = 'smart-tile-subscription';
    } else if (type === 'security') {
        internalURL = findPathByAlias('SignInCredentialsView');
        tileId = 'smart-tile-security';
    } else if (type === 'nvc-accessory-orders') {
        internalURL = findPathByAlias('OrderHistoryView');
        tileId = 'smart-tile-nvc-order-history';
    }

    return { internalURL, tileId };
};

const SmartTile = (props: SmartTileProps) => {
    const {
        isMobile,
        addVehicleContent,
        displayModal,
        updateAddVehicleStatusWithVin,
        displayAddVehicleModal,
        analyticEventName,
        ctaTitle,
        ctaHref,
        className,
        children,
        ctaTargetBlank,
        ctaAriaLabel,
        type,
        setIsLoading,
        vehiclesOnOrder,
        garageVehicles,
    } = props;

    const appConfig = new AppConfigurationService();
    const currentAppConfiguration = appConfig.getAppConfiguration();
    const twoLetterCountryCode = appConfig.get2LetterCountryCode();
    const isEu = EU_COUNTRIES.includes(twoLetterCountryCode);
    const [fireAnalytics] = useAnalytics();
    const isFord = appConfig.brand === 'ford';
    const sendAnalytics = () => {
        fireAnalytics(analyticEventName);
    };

    const { internalURL, tileId } = getInternalURL(
        type,
        ctaHref,
        currentAppConfiguration
    );

    useEffect(() => {
        if (props.openAddVehicleModal && props.currentVehiclesCount === 0) {
            props.handleAddVehicleModal(false);
        }
    }, [props.openAddVehicleModal, props.currentVehiclesCount === 0]);

    const handleNavLinkClick = () => {
        sendAnalytics();
        if (props.currentVehiclesCount === 0) {
            props.handleAddVehicleModal(true);
        } else {
            displayModal && displayModal();
        }
    };

    return (
        <>
            {isMobile || isFord ? (
                <>
                    {internalURL !== null ? (
                        <NavLink
                            to={internalURL}
                            onClick={handleNavLinkClick}
                            aria-label={ctaAriaLabel || ctaTitle}
                            data-testid={`${className?.split(' ')[0]}-nav-link`}
                        >
                            <div
                                className={`smart-tile ${className}`}
                                data-testid="smart-tile"
                                id={tileId}
                            >
                                {children}
                                {internalURL && (
                                    <SecondaryButton
                                        internal
                                        dataTestId="smart-tile-cta"
                                        buttonType={
                                            appConfig.brand === 'lincoln'
                                                ? 'chevron-right'
                                                : 'blue-filled'
                                        }
                                        className="fmc-text-button fmc-text-button--chevron-right"
                                        link={internalURL}
                                        linkTarget={
                                            ctaTargetBlank ? '_blank' : ''
                                        }
                                        onClick={() => {
                                            sendAnalytics();
                                            displayModal && displayModal();
                                        }}
                                        aria-label={
                                            ctaAriaLabel
                                                ? ctaAriaLabel
                                                : ctaTitle
                                        }
                                    >
                                        {ctaTitle}
                                    </SecondaryButton>
                                )}
                                {displayAddVehicleModal && (
                                    <AddVehicle
                                        ctaType="link"
                                        buttonText={ctaTitle}
                                        addButtonPlusSign={false}
                                        isEURegion={isEu}
                                        updateAddVehicleStatusWithVin={
                                            updateAddVehicleStatusWithVin
                                        }
                                        addVehicleContent={addVehicleContent}
                                        displayAddButton
                                        setIsLoading={setIsLoading}
                                        currentVehiclesCount={
                                            props.currentVehiclesCount
                                        }
                                        setCurrentVehiclesCount={
                                            props.setCurrentVehiclesCount
                                        }
                                        fromSmartTile={true}
                                        fromConnectedServices={true}
                                        vehiclesOnOrder={vehiclesOnOrder}
                                        garageVehicles={garageVehicles}
                                        setIsNewVehicleAdded={
                                            props.setIsNewVehicleAdded
                                        }
                                        setTeslaBannerNotificationStatus={
                                            props.setTeslaBannerNotificationStatus
                                        }
                                    />
                                )}
                            </div>
                        </NavLink>
                    ) : (
                        <Link
                            href={ctaHref}
                            target={ctaTargetBlank ? '_blank' : ''}
                            onClick={handleNavLinkClick}
                            data-testid="smart-tile-link"
                            aria-label={ctaAriaLabel || ctaTitle}
                        >
                            <div
                                className={`smart-tile ${className}`}
                                data-testid="smart-tile"
                            >
                                {children}
                                <SecondaryButton
                                    buttonType={
                                        appConfig.brand === 'lincoln'
                                            ? 'chevron-right'
                                            : 'blue-filled'
                                    }
                                    className="fmc-text-button fmc-text-button--chevron-right"
                                    link={ctaHref}
                                    linkTarget={ctaTargetBlank ? '_blank' : ''}
                                    onClick={() => {
                                        sendAnalytics();
                                        displayModal && displayModal();
                                    }}
                                    data-testid="smart-tile-secondary-button"
                                    aria-label={
                                        ctaAriaLabel ? ctaAriaLabel : ctaTitle
                                    }
                                >
                                    {ctaTitle}
                                </SecondaryButton>
                            </div>
                        </Link>
                    )}
                </>
            ) : (
                <div
                    className={`smart-tile ${className}`}
                    data-testid="smart-tile"
                    id={tileId}
                >
                    {children}
                    {internalURL && (
                        <SecondaryButton
                            internal
                            buttonType={
                                appConfig.brand === 'lincoln'
                                    ? 'chevron-right'
                                    : 'blue-filled'
                            }
                            className={`fmc-text-button fmc-text-button--chevron-right ${props.connectedServicesClassName}`}
                            link={internalURL}
                            linkTarget={ctaTargetBlank ? '_blank' : ''}
                            onClick={() => {
                                sendAnalytics();
                                displayModal && displayModal();
                            }}
                            aria-label={ctaAriaLabel ? ctaAriaLabel : ctaTitle}
                        >
                            {ctaTitle}
                        </SecondaryButton>
                    )}
                    {!internalURL && !displayAddVehicleModal && (
                        <SecondaryButton
                            buttonType={
                                appConfig.brand === 'lincoln'
                                    ? 'chevron-right'
                                    : 'blue-filled'
                            }
                            className="fmc-text-button fmc-text-button--chevron-right"
                            link={ctaHref}
                            linkTarget={ctaTargetBlank ? '_blank' : ''}
                            onClick={() => {
                                sendAnalytics();
                                displayModal && displayModal();
                            }}
                            aria-label={ctaAriaLabel ? ctaAriaLabel : ctaTitle}
                        >
                            {ctaTitle}
                        </SecondaryButton>
                    )}
                    {displayAddVehicleModal && (
                        <AddVehicle
                            ctaType="link"
                            buttonText={ctaTitle}
                            addButtonPlusSign={false}
                            isEURegion={isEu}
                            updateAddVehicleStatusWithVin={
                                updateAddVehicleStatusWithVin
                            }
                            addVehicleContent={addVehicleContent}
                            displayAddButton
                            setIsLoading={setIsLoading}
                            currentVehiclesCount={props.currentVehiclesCount}
                            setCurrentVehiclesCount={
                                props.setCurrentVehiclesCount
                            }
                            fromSmartTile={true}
                            vehiclesOnOrder={vehiclesOnOrder}
                            garageVehicles={garageVehicles}
                            setIsNewVehicleAdded={props.setIsNewVehicleAdded}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default SmartTile;
