import { DSL_BASE_URL, DSL_API_PATH } from '@constants';
import AuthenticationService from '../authentication-service/authentication-service';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { HttpsProxyAgent } from 'https-proxy-agent';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

interface UpdateUserResponse {
    httpStatus: string;
    status: string;
    requestStatus?: string;
    error?: {
        message: string;
        statusCode: string;
        statusContext: string;
    };
    lastRequested?: string;
    version?: string;
    statusCode?: string | null;
    message?: string | null;
}

export interface AdditionalAddressResponse {
    error: {
        attributes: unknown;
        dataErrors: [
            {
                code: string;
                message: string;
                name: string;
                value: string;
            }
        ];
        errorCode: string;
        messages: string;
    };
    httpStatus: number;
    lastRequested: string;
    requestStatus: string;
    status: number;
    value: {
        profile: {
            additionalAddressList: [
                {
                    addressLine1: string;
                    addressLine2: string;
                    addressLine3: string;
                    addressLine4: string;
                    chargingFlag: string;
                    city: string;
                    country: string;
                    countyRegion: string;
                    effectiveEndDate: string;
                    effectiveStartDate: string;
                    id: string;
                    latitude: string;
                    longitude: string;
                    newAddress: boolean;
                    postalCode: string;
                    postalCodeSuffix: string;
                    stateOrProvince: string;
                }
            ];
            additionalAddressMessage: string;
            address1: string;
            address2: string;
            address3: string;
            address4: string;
            affiliation: [unknown];
            alternatePhoneNumber: string;
            alternatePhoneNumber2: string;
            alternatePhoneNumber2Extension: string;
            alternatePhoneNumberExtension: string;
            appreciationId: string;
            birthYearMonth: string;
            city: string;
            communicationChannel: {};
            communicationMethod: number;
            communityIcon: string;
            companyName: string;
            consumerType: string;
            country: string;
            deviceID: string;
            deviceLanguage: string;
            email: string;
            firstName: string;
            fordCredit: string;
            gender: string;
            language: string;
            lastName: string;
            lastUpdatedDate: string;
            maritalStatus: string;
            middleName: string;
            mobilePhoneNumber: string;
            motorCraft: string;
            notificationPrefBillReminder: number;
            notificationPrefMarketingMsg: number;
            notificationPrefOwnership: number;
            notificationPrefVehicleHealth: number;
            notificationPrefVehicleRemote: number;
            partnerRelationships: {};
            phoneNumber: string;
            phoneNumberExtension: string;
            postalCodeSuffix: string;
            preferredLanguage: string;
            privacyAccepted: number;
            privacyPreferences: [{}];
            privacyVersion: string;
            profileAttributes: [{}];
            profileStoreName: string;
            secondLastName: string;
            sendMarketingEmails: number;
            socialMediaProvider: string;
            state: string;
            suffix: string;
            termsAccepted: number;
            termsVersion: string;
            timeZone: string;
            title: string;
            uomDistance: number;
            uomPressure: string;
            uomSpeed: string;
            uomTemperature: string;
            userDisplayName: string;
            userGuid: string;
            userId: string;
            userIdentity: {
                identificationCountry: string;
                identificationSubType: string;
                identificationType: string;
                identificationValue: string;
            };
            verifiedPhoneNumber: string;
            version: string;
            versionNumber: string;
            zip: string;
        };
        vehicles: [{}];
    };
    version: string;
}

interface UpdateAddressResponse {
    updateProfileResponse: UpdateUserResponse;
    updateAddressBookResponseList: [{ status: string }];
}
export default class UserAccountService {
    public async updateUserPersonalInfo(
        userDetails
    ): Promise<UpdateUserResponse> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.UPDATE_USER_INFO;
        const authenticationService = new AuthenticationService();
        return HttpService.put<any>(
            `${url}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`,
            userDetails,
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                proxy: false,
                httpAgent: proxyAgent,
            }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    console.error(`error`);
                    authenticationService.login();
                } else {
                    return error;
                }
            });
    }
    public async getAdditionalAddress(): Promise<AdditionalAddressResponse> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.PROFILE_WITH_VEHICLES;
        const authenticationService = new AuthenticationService();

        return authenticationService
            .onIsAuthenticated()
            .then(() =>
                HttpService.get<any>(
                    `${url}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`,
                    false,
                    {
                        headers:
                            HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                        proxy: false,
                        httpAgent: proxyAgent,
                    }
                )
            )
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    console.error(`User Timeout`);
                    authenticationService.login();
                }
            });
    }
    public async updateUserAdditionalAddress(
        userDetails
    ): Promise<UpdateAddressResponse> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.UPDATE_USER_ADDRESS;
        const authenticationService = new AuthenticationService();

        return HttpService.post<any>(
            `${url}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`,
            userDetails,
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                proxy: false,
                httpAgent: proxyAgent,
            }
        )
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    console.error('User timeout');
                    authenticationService.login();
                } else {
                    return e;
                }
            });
    }
}
