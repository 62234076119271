import React, { useCallback, useEffect, useRef } from 'react';
import { KEYBOARD_KEYS } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import PrimaryButton from '../primary-button/primary-button';
import { LinkButton } from './link-button/link-button';
import './modal.scss';
import {
    ModalContextProps,
    ModalProps,
    useModalContext,
} from '@contexts/modalContext';
import { createPortal } from 'react-dom';

const cancelCloseIcon = './icons/cancel-close.svg';

interface Props extends React.HTMLProps<HTMLDivElement> {
    name?: string;
    primaryBtnLabel?: string;
    secondaryBtnLabel?: string;
    secondaryBtnAriaLabel?: string;
    primaryBtnAriaLabel?: string;
    closeBtnAriaLabel?: string;
    onPrimaryBtnClick?: (event: React.MouseEvent) => void;
    onSecondaryBtnClick?: (
        event: React.MouseEvent | React.KeyboardEvent
    ) => void;
    onSecondaryButtonClick?: (
        event: React.MouseEvent | React.KeyboardEvent
    ) => void;
    hideCloseButton?: boolean;
    onClose?: () => void;
    ariaLabel?: string;
    isVisible?: boolean;
    modalContentClass?: string;
    modalFooterClass?: string;
}

const closeModal = (
    event: React.MouseEvent | MouseEvent | React.KeyboardEvent | KeyboardEvent,
    modalContext: ModalContextProps,
    modal: HTMLDivElement,
    closeButton: HTMLButtonElement
) => {
    if (modalContext.modalType.preventClose === true) {
        return;
    }

    const isTargetModal = event.target === modal;
    const isCurrentTargetCloseIcon = event.currentTarget === closeButton;
    const isEscKeyPressed = 'key' in event && event.key === KEYBOARD_KEYS.ESC;

    if (modal && (isTargetModal || isCurrentTargetCloseIcon || isEscKeyPressed))
        modalContext.modalType.onAfterClose &&
            modalContext.modalType.onAfterClose();
};

export const handleTabKey = (
    event: KeyboardEvent | React.KeyboardEvent,
    modalRef: HTMLDivElement
) => {
    const focusableElements = modalRef.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableElement = focusableElements[0] as HTMLElement;
    const lastFocusableElement = focusableElements[
        focusableElements.length - 1
    ] as HTMLElement;

    if (!event.shiftKey && document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
    }
    if (event.shiftKey && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
    }
};

const showModal = () => {
    const element = document.getElementsByClassName(
        'modal'
    )[0] as HTMLDivElement;
    element && (element.style.display = 'block');
};

const hideModal = () => {
    const element = document.getElementsByClassName(
        'modal'
    )[0] as HTMLDivElement;
    element && (element.style.display = 'none');
};

const getModalContentClass = (modalContext: ModalContextProps) => {
    return modalContext.modalType.modalContentClass
        ? `modal-content ${modalContext.modalType.modalContentClass}`
        : 'modal-content';
};

const getModalFooterClass = (modalContext: ModalContextProps) => {
    return modalContext.modalType.modalFooterClass
        ? `modal-footer ${modalContext.modalType.modalFooterClass}`
        : 'modal-footer';
};

export const Modal = (props: ModalProps | Props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const isFord = appConfig.brand === 'ford';
    const { modalContext } = useModalContext();

    const closeModalCallback = useCallback(
        (
            event:
                | MouseEvent
                | React.MouseEvent
                | React.KeyboardEvent
                | KeyboardEvent
        ) =>
            closeModal(
                event,
                modalContext,
                modalRef.current,
                closeIconRef.current
            ),
        [modalContext]
    );

    const setDefaultFocus = () => {
        closeIconRef?.current?.focus();
    };

    const getTargetElementClasses = (target: EventTarget) => {
        let targetClasses = '';
        (target as HTMLElement).classList.forEach(
            (cn) => (targetClasses += `.${cn}`)
        );
        return targetClasses;
    };

    const onDocumentClicked = (event: MouseEvent) => {
        const targetClasses = getTargetElementClasses(event.target);
        const targetElement = event.target as Element;
        const element = document.getElementsByClassName(
            modalContext.modalType.name || props.name
        )[0] as HTMLDivElement;

        if (
            !modalContentRef?.current?.contains(event.target as Node) &&
            element?.querySelectorAll(targetClasses).length <= 0 &&
            targetElement.id !== 'add-vehicle-btn_link'
        ) {
            closeModalCallback(event);
        }
    };

    const handleEscapeKey = (event: KeyboardEvent | React.KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeModalCallback(event);
        }
    };

    const keyDownHandler = (event: KeyboardEvent | React.KeyboardEvent) => {
        handleEscapeKey(event);
        if (event.key === 'Tab') {
            handleTabKey(event, modalRef.current);
        }
    };

    useEffect(() => {
        if (modalContext.modalType.name) {
            showModal();
            document.body.classList.add('open');
            document.addEventListener('mousedown', onDocumentClicked, false);
            document.addEventListener('keydown', keyDownHandler, false);
            setDefaultFocus();
        }
        return () => {
            hideModal();
            document.body.classList.remove('open');
            document.removeEventListener('mousedown', onDocumentClicked, false);
            document.removeEventListener('keydown', keyDownHandler, false);
        };
    }, [modalContext.modalType.name]);

    // modal content class used to add custom styles to the modal
    const modalContentClass = getModalContentClass(modalContext);
    const modalFooterClass = getModalFooterClass(modalContext);

    if (!modalContext.modalType.name) return null;

    return createPortal(
        <div
            ref={modalRef}
            className={`modal ${
                modalContext.modalType.name
            } open fmc-dialog--opened fmc-dialog ${
                modalContext.modalType.className || ''
            }`}
            data-testid={modalContext.modalType.name}
            role="dialog"
            aria-label={modalContext.modalType.ariaLabel}
            aria-modal={true}
            onKeyDown={keyDownHandler}
        >
            <div
                className={`${modalContentClass} fmc-dialog__content`}
                ref={modalContentRef}
                data-testid="modal-content"
            >
                <div className="modal-header">
                    {modalContext.modalType.preventClose !== true && (
                        <button
                            ref={closeIconRef}
                            data-testid="modal-close"
                            onClick={(e) => closeModalCallback(e)}
                            aria-label={
                                modalContext.modalType.closeButtonAriaLabel ||
                                'Close Dialog'
                            }
                            className={`fmc-dialog__close ${
                                isLincoln
                                    ? 'fds-icon fds-font--ford-icons__clear'
                                    : ''
                            }`}
                        >
                            {isFord && (
                                <img
                                    src={cancelCloseIcon}
                                    alt="close-icon"
                                ></img>
                            )}
                        </button>
                    )}
                </div>
                <div className="modal-body" data-testid="modal-body">
                    {modalContext.modalType.children}
                </div>
                {isLincoln && !modalContext.modalType.isLincolnNvc ? (
                    <div className={modalFooterClass}>
                        {modalContext.modalType.primaryButtonLabel && (
                            <PrimaryButton
                                ref={primaryButtonRef}
                                onClick={
                                    modalContext.modalType.onPrimaryButtonClick
                                }
                                ariaLabel={
                                    modalContext.modalType
                                        .primaryButtonAriaLabel ||
                                    modalContext.modalType.primaryButtonLabel
                                }
                                testId={`${modalContext.modalType.name}-primary-button`}
                                data-testid="test-modal-primary-button"
                                isNvc={false}
                            >
                                {modalContext.modalType.primaryButtonLabel}
                            </PrimaryButton>
                        )}
                        {modalContext.modalType.secondaryButtonLabel && (
                            <div className="secondary-button">
                                <LinkButton
                                    class={isLincoln ? '' : 'secondary'}
                                    dataTestId={`${modalContext.modalType.name}-secondary-button`}
                                    ref={cancelRef}
                                    label={
                                        modalContext.modalType
                                            .secondaryButtonLabel
                                    }
                                    ariaLabel={
                                        modalContext.modalType
                                            .secondaryButtonAriaLabel ||
                                        modalContext.modalType
                                            .secondaryButtonLabel
                                    }
                                    onClick={
                                        modalContext.modalType
                                            .onSecondaryButtonClick
                                    }
                                    data-testid="test-modal-secondary-button"
                                    isLincolnNvc={false}
                                />
                            </div>
                        )}
                    </div>
                ) : null}

                {(isFord || modalContext.modalType.isLincolnNvc) && (
                    <div className={modalFooterClass}>
                        {modalContext.modalType.secondaryButtonLabel && (
                            <div className="secondary-button">
                                <LinkButton
                                    class={isLincoln ? '' : 'secondary'}
                                    dataTestId={`${modalContext.modalType.name}-secondary-button`}
                                    ref={cancelRef}
                                    label={
                                        modalContext.modalType
                                            .secondaryButtonLabel
                                    }
                                    ariaLabel={
                                        modalContext.modalType
                                            .secondaryButtonAriaLabel ||
                                        modalContext.modalType
                                            .secondaryButtonLabel
                                    }
                                    onClick={
                                        modalContext.modalType
                                            .onSecondaryButtonClick
                                    }
                                    isLincolnNvc={
                                        modalContext.modalType.isLincolnNvc
                                    }
                                />
                            </div>
                        )}
                        {modalContext.modalType.primaryButtonLabel && (
                            <PrimaryButton
                                ref={primaryButtonRef}
                                onClick={
                                    modalContext.modalType.onPrimaryButtonClick
                                }
                                ariaLabel={
                                    modalContext.modalType
                                        .primaryButtonAriaLabel ||
                                    modalContext.modalType.primaryButtonLabel
                                }
                                testId={`${modalContext.modalType.name}-primary-button`}
                                isNvc={modalContext.modalType.isLincolnNvc}
                            >
                                {modalContext.modalType.primaryButtonLabel}
                            </PrimaryButton>
                        )}
                    </div>
                )}
            </div>
        </div>,
        document.getElementById('portal')
    );
};
