import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import './bing-maps.scss';
import MicrosoftService from '@services/microsoft-service/microsoft-service';
import { Dealer } from '@models/preferred-dealer';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

const fordDealerLocationPin = './icons/ford-marker.svg';
const lincolnDealerLocationPin = './icons/lincoln-marker.svg';
const fordDealerLocationPinDrawer = './icons/Marker.svg';

interface Props {
    scriptLoaded?: boolean;
    dealerResults: Dealer[];
    setDealerResults: Dispatch<SetStateAction<Dealer[]>>;
    id?: string;
    inDrawer?: boolean;
    zoom?: number;
}

export const BingMaps = (props: Props) => {
    const service: MicrosoftService = new MicrosoftService();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapsObject = service.getMapsObject();
    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    const requiredMapDataExists = !!props.scriptLoaded && !!mapsObject;
    const [mapExists, setMapExists] = useState(false);
    const mapLoadAttempts = useRef(0);

    useEffect(() => {
        if (requiredMapDataExists && props.dealerResults) {
            if (mapExists) {
                tryRenderMap();
            } else {
                setTimeout(() => {
                    tryRenderMap();
                }, 1000);
            }
        }
    }, [props.dealerResults, props.scriptLoaded, mapExists]);

    function tryRenderMap() {
        const map = renderMap();

        if (!map && mapLoadAttempts.current < 5) {
            mapLoadAttempts.current++;
            setTimeout(tryRenderMap, 1000);
        }
    }

    function renderMap() {
        const dealerLocations = getDealerLocations(props.dealerResults);
        const map = props.id ? createSinglePointMap() : createMap();
        if (!map) return;

        const pinInfobox = createPinInfobox();
        const pinLayer = createPinLayer(dealerLocations);

        map.layers.insert(pinLayer);
        pinInfobox.setMap(map);

        !props.id && setMapView(map, dealerLocations);

        if (props.id) {
            const interval = setInterval(() => {
                if (map._mapHasLoaded === true) {
                    setMapExists(true);
                    clearInterval(interval);
                }
            }, 100);
        }

        return map;
    }

    function getDealerLocations(dealerData: Dealer[]) {
        return dealerData.map((dealer) => {
            return service.getMicrosoftLocation(
                parseFloat(dealer.latitude),
                parseFloat(dealer.longitude)
            );
        });
    }

    const createSinglePointMap = () => {
        if (!mapExists) {
            try {
                const id: string = props.id ? props.id : 'myMap';
                const map = mapsObject.Map(`#${id}`, {
                    center: new mapsObject.Location(
                        props.dealerResults[0].latitude,
                        props.dealerResults[0].longitude
                    ),
                    zoom: props.zoom || 8,
                    mapSize: '441, 150',
                });
                map &&
                    id !== 'myMap' &&
                    map.setOptions({
                        disableScrollWheelZoom: true,
                        disablePanning: true,
                        disableZooming: true,
                        allowHidingLabelsOfRoad: true,
                        showLocateMeButton: false,
                        showMapTypeSelector: false,
                        disableKeyboardInput: true,
                    });

                return map;
            } catch (e) {
                console.error('Bing maps encountered error while creating map');
            }
        }
    };

    function createMap() {
        try {
            return mapsObject.Map('#myMap');
        } catch (e) {
            console.error('Bing maps encountered error while creating map');
        }
    }

    function createPinInfobox() {
        return new mapsObject.Infobox(mapsObject.Location(0, 0), {
            visible: false,
        });
    }

    function createPinLayer(dealerLocations: any[]) {
        const pinLayer = new mapsObject.Layer();
        const icons = isFord ? fordDealerLocationPin : lincolnDealerLocationPin;
        const drawerIcon = fordDealerLocationPinDrawer;

        dealerLocations.forEach((location, i) => {
            const pin = new mapsObject.Pushpin(location, {
                icon: props.inDrawer ? drawerIcon : icons,
                text: props.inDrawer ? '' : `${i + 1}`,
                typeName: 'pinStyle',
                textOffset: new mapsObject.Point(0, 10),
            });
            pinLayer.add(pin);
        });

        return pinLayer;
    }

    function setMapView(map, dealerLocations: any[]) {
        const bestView = mapsObject.LocationRect.fromLocations(dealerLocations);

        if (props.id) {
            map.setView({
                zoom: props.zoom,
            });
        } else {
            map.setView({
                bounds: bestView,
                zoom: 8,
            });
        }
    }

    return (
        requiredMapDataExists && (
            <div className="map-holder" ref={mapContainerRef}>
                <div id={props.id || 'myMap'} data-testid="bing-map"></div>
            </div>
        )
    );
};
