import React, { useEffect, useState } from 'react';
import { usePreferredDealerDrawerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-drawer-content';
import { VehicleOptions } from '@smart-tiles/smart-tile-preferred-dealer/preferred-dealer-modal';
import BingService, {
    BingDealerInfo,
} from '@services/bing-service/bing-service';
import { useSideDrawerContext } from '@contexts/sideDrawerContext';
import { useAnalytics } from '@hooks/use-analytics';
import HttpService from '@services/http-service/http-service';
import { useNavigate } from 'react-router-dom';
import {
    ProfileWithVehiclesResponse,
    VehicleDetail,
} from '@models/profile-with-vehicles';
import PreferredDealerService from '@services/preferred-dealer-service/preferred-dealer-service';
import { scriptService } from '@services/script-service/script-service';
import { Dealer } from '@models/preferred-dealer';
import randomNumberUtil from '@utils/random-number-util/random-number-util';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { BingMaps } from '@sections/maps/bing-maps';
import { VEHICLE_CARD_STORAGE_KEY } from '@constants';
import { findPathByAlias } from '@routes/routesList';
import ProfileService from '@services/profile-service/profile-service';

import './preferred-dealer-drawer.scss';

const PreferredDealerDrawer = () => {
    const [fireAnalytics] = useAnalytics();
    const httpService = HttpService;
    const bingService = new BingService(httpService);
    const navigate = useNavigate();
    const { isVisible, resetContext } = useSideDrawerContext();
    const preferredDealerDrawerContent = usePreferredDealerDrawerContent();
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse>(null);
    const [vehicleData, setVehicleData] = useState<VehicleOptions[]>(null);

    const [rerender, setRerender] = useState(false);
    const forceRerender = () => {
        setRerender(!rerender);
    };

    const generateClassNameForVehiclesWithMap = (
        vehicle: VehicleOptions
    ): string => {
        let className = '';

        if (vehicle?.preferredDealerCode && vehicle?.mapLoaded) {
            className = 'has-map';
        } else if (vehicle?.dealerInfoError) {
            className = 'has-dealer-error';
        }

        return className;
    };

    const closeDrawer = (): void => {
        resetContext();
    };

    const generateButtonLabel = (vehicle: VehicleOptions): string => {
        return vehicle.preferredDealerCode
            ? preferredDealerDrawerContent?.editButtonLabelText
            : preferredDealerDrawerContent?.selectButtonLabelText;
    };

    const generateButtonAriaLabel = (vehicle: VehicleOptions): string => {
        return vehicle.preferredDealerCode
            ? preferredDealerDrawerContent?.editButtonAriaLabel
            : preferredDealerDrawerContent?.selectButtonAriaLabel;
    };

    const checkRequiredDealerInfoExists = (
        dealerInfo: BingDealerInfo
    ): boolean => {
        return (
            !!dealerInfo?.dealerName &&
            !!dealerInfo?.streetAddress &&
            !!dealerInfo?.city &&
            !!dealerInfo?.state &&
            !!dealerInfo?.zip &&
            !!dealerInfo?.phone &&
            !!dealerInfo.latitude &&
            !!dealerInfo.longitude
        );
    };

    const handleVehicleChoice = (vehicle: VehicleOptions): void => {
        const nameplate = `${vehicle.year} ${vehicle.model}`;

        if (vehicle.preferredDealerCode) {
            fireAnalytics('editVehiclePreferredDealerOnclickEvent', '', {
                nameplate,
            });
        } else {
            fireAnalytics('selectVehiclePreferredDealerOnclickEvent', '', {
                nameplate,
            });
        }
    };

    const getVehicleWithDealerData = async (
        vehicle: VehicleDetail
    ): Promise<VehicleOptions> => {
        const {
            modelName,
            vin,
            modelYear,
            nickName,
            preferredDealer,
        }: VehicleDetail = vehicle;

        const listItem: VehicleOptions = {
            nickname: nickName,
            model: modelName,
            year: modelYear,
            vin,
            preferredDealerCode: preferredDealer,
            mapLoaded: false,
        };
        let dealerInfoArray: BingDealerInfo[] = null;

        if (!vehicle?.preferredDealer) {
            listItem.noDealerSelectedText = null;
            return listItem;
        }

        try {
            dealerInfoArray = await bingService.getDealerInfoById(
                vehicle.preferredDealer
            );
        } catch (e) {
            console.log('error in bing service getting dealer info...');
        }

        let dealerInfo: BingDealerInfo;

        if (dealerInfoArray?.length > 0) {
            dealerInfo = dealerInfoArray[0];
        }
        const allRequiredDealerInfoExists =
            checkRequiredDealerInfoExists(dealerInfo);

        if (allRequiredDealerInfoExists) {
            listItem.preferredDealerName = dealerInfo.dealerName;
            listItem.preferredDealerAddress = dealerInfo.streetAddress;
            listItem.preferredDealerCityStateZip = `${dealerInfo.city}, ${dealerInfo.state} ${dealerInfo.zip}`;
            listItem.preferredDealerPhoneNumber = dealerInfo.phone;
            listItem.preferredDealerLatitude = dealerInfo.latitude;
            listItem.preferredDealerLongitude = dealerInfo.longitude;
        } else {
            listItem.dealerInfoError =
                preferredDealerDrawerContent?.dealerInfoRetrievalError;
        }

        const dealerInfoStuff = await generatePreferredDealerInfo(
            listItem.preferredDealerLatitude,
            listItem.preferredDealerLongitude
        );

        return loadScriptIfDealerInfoExists(dealerInfoStuff, listItem);
    };

    const loadScriptIfDealerInfoExists = async (
        dealerInfo: Dealer[],
        vehicle: VehicleOptions
    ): Promise<VehicleOptions> => {
        if (dealerInfo?.length) {
            const setMapLoaded = (): void => {
                vehicle.mapLoaded = true;
                return null;
            };

            await scriptService
                .loadBingMapsScript(
                    forceRerender,
                    randomNumberUtil.getRandomNumber().toFixed(4)
                )
                .then(() => {
                    setMapLoaded();
                });

            vehicle.dealerObject = dealerInfo[0];
        }

        return vehicle;
    };

    const generatePreferredDealerInfo = async (
        lat: string,
        lng: string
    ): Promise<Dealer[]> => {
        const httpService = HttpService;
        const service = new PreferredDealerService(httpService);

        const dealerResults = await service.getPreferredDealerResults({
            make: 'Ford',
            latitude: lat,
            longitude: lng,
        });

        return dealerResults?.dealer || null;
    };

    const buildModalData = async (): Promise<void> => {
        const vehiclesWithPreferredDealerData = await Promise.all(
            profileData?.vehicles.map(getVehicleWithDealerData)
        );

        vehiclesWithPreferredDealerData.sort((a, b) => {
            return parseInt(b.year) - parseInt(a.year);
        });

        setVehicleData(vehiclesWithPreferredDealerData);
    };

    useEffect(() => {
        const profileService = new ProfileService();

        profileService.requestLite(true).then((profile) => {
            setProfileData(profile || null);
        });

        isVisible === true && fireAnalytics('ownerPreferredDealerGarage');
    }, [isVisible === true]);

    useEffect(() => {
        if (profileData && preferredDealerDrawerContent && !vehicleData) {
            (async () => await buildModalData())();
        }
    }, [profileData]);

    useEffect(() => {
        if (vehicleData?.length && preferredDealerDrawerContent) {
            setTimeout(() => {
                const closeButton: HTMLElement = document.getElementById(
                    'preferred-dealer-drawer-close-button'
                );
                closeButton.focus();
            }, 500);
        }
    }, [vehicleData, preferredDealerDrawerContent]);

    return preferredDealerDrawerContent && vehicleData?.length ? (
        <div
            className="preferred-dealer-drawer__container"
            data-testid="preferred-dealer-drawer-container"
        >
            <h1
                className="preferred-dealer-drawer-header-text"
                data-testid="preferred-dealer-drawer-header-text"
            >
                {preferredDealerDrawerContent.headerText}
            </h1>

            <hr
                className="full-line--dark"
                data-testid="preferred-dealer-drawer-horizontal-rule"
            />

            <h2
                className="preferred-dealer-drawer-subtitle-text"
                data-testid="preferred-dealer-drawer-subtitle-text"
            >
                {preferredDealerDrawerContent.subtitleText}
            </h2>

            <div
                className="preferred-dealer-drawer__vehicle-list"
                data-testid="preferred-dealer-drawer-vehicle-list"
            >
                {vehicleData?.length > 0 &&
                    vehicleData.map(
                        (vehicle: VehicleOptions, index: number) => {
                            return (
                                <>
                                    {!vehicleData[0].preferredDealerCode &&
                                        index !== 0 && (
                                            <hr className="line-before-individual-vehicle" />
                                        )}
                                    <div
                                        data-testid="individual-vehicle"
                                        className={`preferred-dealer-drawer__vehicle-list--individual-vehicle ${generateClassNameForVehiclesWithMap(
                                            vehicle
                                        )}`}
                                    >
                                        {vehicle.preferredDealerCode &&
                                            vehicle.mapLoaded && (
                                                <div className="map">
                                                    <BingMaps
                                                        id={`myMap${index + 1}`}
                                                        dealerResults={[
                                                            vehicle.dealerObject,
                                                        ]}
                                                        setDealerResults={null}
                                                        scriptLoaded={
                                                            vehicle.mapLoaded
                                                        }
                                                        inDrawer
                                                        zoom={12}
                                                    />
                                                </div>
                                            )}

                                        {!vehicle.preferredDealerCode && (
                                            <hr className="full-line" />
                                        )}
                                        <h3 className="ymm">
                                            {vehicle.year} {vehicle.model}
                                        </h3>
                                        <p className="vin">{vehicle.vin}</p>
                                        {vehicle.dealerInfoError && (
                                            <>
                                                <p className="dealer-info-error">
                                                    {vehicle.dealerInfoError}
                                                </p>
                                                <hr className="full-line" />
                                            </>
                                        )}
                                        <button
                                            className="preferred-dealer-drawer__edit-button"
                                            data-testid="preferred-dealer-drawer-edit-button"
                                            aria-label={generateButtonAriaLabel(
                                                vehicle
                                            )}
                                            onClick={() => {
                                                handleVehicleChoice(vehicle);
                                                sessionStorage.setItem(
                                                    VEHICLE_CARD_STORAGE_KEY,
                                                    JSON.stringify({
                                                        vin: vehicle.vin,
                                                    })
                                                );
                                                navigate(
                                                    findPathByAlias(
                                                        'PreferredDealerView'
                                                    )
                                                );
                                                closeDrawer();
                                            }}
                                        >
                                            {generateButtonLabel(vehicle)}
                                        </button>
                                        {vehicle.preferredDealerCode &&
                                            !vehicle.dealerInfoError && (
                                                <>
                                                    <div className="preferred-dealer-info">
                                                        <hr className="half-line" />
                                                        <h4 className="dealer-name">
                                                            {
                                                                vehicle.preferredDealerName
                                                            }
                                                        </h4>
                                                        <p className="address">
                                                            {
                                                                vehicle.preferredDealerAddress
                                                            }
                                                            ,{' '}
                                                            {
                                                                vehicle.preferredDealerCityStateZip
                                                            }
                                                        </p>
                                                        <p className="phone-number">
                                                            {
                                                                vehicle.preferredDealerPhoneNumber
                                                            }
                                                        </p>
                                                    </div>
                                                    <hr className="full-line" />
                                                </>
                                            )}
                                    </div>
                                </>
                            );
                        }
                    )}
            </div>
        </div>
    ) : (
        <ActivityIndicator
            className="preferred-dealer-drawer__spinner"
            data-testid="preferred-dealer-drawer-spinner"
        />
    );
};

export default PreferredDealerDrawer;
