import React from 'react';

interface VinMismatchProps {
    vinMismatchContent: string;
}

const AddVehicleVinMismatchContent = (props: VinMismatchProps) => {
    return (
        <>
            {props.vinMismatchContent && (
                <div className="text-center">
                    <h3
                        className="fmc-type--heading5 fds-color__text--primary"
                        data-testid="vin-mismatch-message"
                    >
                        {props.vinMismatchContent}
                    </h3>
                </div>
            )}
        </>
    );
};

export default AddVehicleVinMismatchContent;
