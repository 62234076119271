import React from 'react';

export type ChevronDirection = 'left' | 'right';

interface Props {
    direction: ChevronDirection;
    color?: string;
}

const TertiaryButtonChevron = (props: Props) => {
    return (
        <>
            {props.direction === 'left' && (
                <span
                    className="caret left-caret"
                    role="img"
                    aria-label="Left caret"
                    data-testid="tertiary-button-chevron-left"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.19995 12L14.4 19.2001L15.24 18.3601L8.87995 12L15.24 5.64005L14.4 4.80005L7.19995 12Z"
                            fill=""
                        />
                    </svg>
                </span>
            )}
            {props.direction === 'right' && (
                <span
                    className="caret right-caret"
                    role="img"
                    aria-label="Right caret"
                    data-testid="tertiary-button-chevron-right"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.8 12L9.60001 4.8L8.76001 5.64L15.12 12L8.76001 18.36L9.60001 19.2L16.8 12Z"
                            fill={props.color}
                            data-testid="tertiary-button-chevron-right-path"
                        />
                    </svg>
                </span>
            )}
        </>
    );
};

export default TertiaryButtonChevron;
