import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { SYNDICATED_HEADER_COUNTRIES } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import './form-fields.scss';
import { PreferenceOption } from '@models/communication-center';
import Tooltip from '@common/tooltip/tooltip';
import CommunicationCenterTooltip from '@views/communication-center-view/components/tooltip/communication-center-tooltip';
import TooltipIcon from '@views/communication-center-view/components/tooltip/tooltip-icon';

const errorNoticeIcon = './icons/error-notice.svg';
const lincolnVisibilityIcon = './icons/lincoln-visibility-icon.svg';
const lincolnInvisibleIcon = './icons/lincoln-invisible-icon.svg';
const lincolnErrorIcon = '/icons/error.svg';

interface InputFieldProps {
    id: string;
    value: string | number;
    name: string;
    label: string;
    showLabel: boolean;
    dataTestId?: string;
    showNewPasswordStatus?: boolean;
    passwordStrengthBorderColor?: string;
    iconForPasswordStatus?: any;
    handleChange?: any;
    onBlur?: any;
    onFocus?: any;
    className?: string;
    validationRules?: any[];
    validationPattern?: string;
    error?: { status: boolean; message: string };
    type?: string;
    rules?: any;
    labelClass?: string;
    readOnly?: boolean;
    disabled?: boolean;
    required?: boolean;
    passwordVisibility?: boolean;
    maxLength?: number;
    showSuccessIcon?: boolean;
    placeholder?: string;
    fromCvot?: boolean;
    validationCharLimit?: number;
    lincolnErrorIcon?: boolean;
}

export const InputField = React.forwardRef(function InputField(
    props: InputFieldProps,
    ref: any
): ReactElement {
    const appConfig = new AppConfigurationService();
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const isFord: boolean = appConfig.brand === 'ford';
    const isRetypePass: boolean = props.name === 'retypePass';
    const errStatus: boolean = props.error?.status;
    const [showIcon, setShowIcon] = useState<boolean>(false);
    const [ariaRole, setAriaRole] = useState<string>(
        isRetypePass ? '' : 'alert'
    );
    const handlePasswordIcon = (): void => {
        setShowIcon(!showIcon);
    };
    // password visible icon status for lincoln
    const showLincolnPasswordVisibleIcon: boolean =
        Boolean(!errStatus && !props.showNewPasswordStatus && props.value) ||
        props.passwordVisibility;
    const fordPasswordIconClass = showIcon
        ? 'fds-font--ford-icons__unmask'
        : 'fds-font--ford-icons__mask';
    const isPasswordField = props.type === 'password';
    const passwordFieldClass: string = isPasswordField ? 'password-field' : '';
    const fordPasswordIsVisible = isPasswordField && props.value && isFord;
    const lincolnPasswordIsVisible =
        isLincoln &&
        isPasswordField &&
        props.value &&
        showLincolnPasswordVisibleIcon;
    const floatingLabelErrorClassNameFord = 'fmc-floating-label--error';
    const floatingLabelErrorClassNameLincoln: string =
        !props.passwordVisibility && 'fmc-floating-label--error';
    const floatingLabelSuccessClass = 'fmc-floating-label--success';
    const inputTypeFromProps: string = props.type && !showIcon && props.type;

    const addAsteriskIfRequired = (): string => {
        if (errStatus && !props.fromCvot) {
            return ` *`;
        }

        return '';
    };
    const generateFloatingLabelClass = (): string => {
        if (errStatus && isFord) {
            return floatingLabelErrorClassNameFord;
        } else if (errStatus && isLincoln) {
            return floatingLabelErrorClassNameLincoln;
        } else if (props?.showSuccessIcon) {
            return floatingLabelSuccessClass;
        }
    };

    const handleBlur = () => {
        if (isRetypePass) setAriaRole('alert');
        if (props.onBlur) {
            props.onBlur();
        }
    };
    return (
        <>
            <div
                className={`fmc-floating-label fmc-floating-label--wrapped-input ${
                    errStatus ? generateFloatingLabelClass() : ''
                }`}
            >
                <input
                    type={inputTypeFromProps || 'text'}
                    name={props.name}
                    id={`${props.id}`}
                    data-testid={props.dataTestId}
                    className={`fmc-input${
                        errStatus ? ` fmc-input--error` : ''
                    } syndicated-input`}
                    aria-label={props.label}
                    value={props.value}
                    onChange={props.handleChange}
                    onFocus={props.onFocus}
                    onBlur={handleBlur}
                    data-validation-rules={props.validationRules || []}
                    data-pattern={props.validationPattern}
                    data-validation-char-limit={props.validationCharLimit}
                    data-label={props.label}
                    placeholder={props.placeholder}
                    style={{
                        borderColor:
                            props.passwordStrengthBorderColor || 'none',
                    }}
                    readOnly={!!props.readOnly}
                    disabled={!!props.disabled}
                    required={!!props.required}
                    maxLength={props.maxLength || null}
                    ref={ref}
                />

                <label
                    htmlFor={props.id}
                    className={`${
                        errStatus ? 'error-label' : ''
                    } ${passwordFieldClass}`}
                >
                    <span className="fmc-floating-label__text">
                        {props.label + addAsteriskIfRequired()}
                    </span>

                    {fordPasswordIsVisible ? (
                        <div>
                            <button
                                style={{
                                    marginLeft: 'auto',
                                    background: 'none',
                                    border: 'none',
                                }}
                                onClick={handlePasswordIcon}
                                aria-label="show password"
                            >
                                {/* Ford visibility icon */}
                                <span
                                    className={`fds-icon fds-icon--32 ${fordPasswordIconClass}`}
                                    aria-hidden={true}
                                ></span>
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </label>

                {lincolnPasswordIsVisible && (
                    <button
                        className="icon-button"
                        onClick={handlePasswordIcon}
                        aria-label="show password"
                    >
                        <img
                            src={
                                showIcon
                                    ? lincolnInvisibleIcon
                                    : lincolnVisibilityIcon
                            }
                            alt={'show current password'}
                        />
                    </button>
                )}
                <div className="form-field-error" role={ariaRole}>
                    {errStatus && (
                        <div className="error-message-wrapper">
                            {lincolnErrorIcon && isLincoln && (
                                <img
                                    className="lincoln-error-icon"
                                    src={lincolnErrorIcon}
                                    alt="close-icon"
                                />
                            )}
                            <span
                                className="error-text"
                                data-testid="error-text"
                            >
                                {props.error.message}
                            </span>
                        </div>
                    )}
                    {props.type === 'password' &&
                        props.label === 'New Password' && (
                            <div>
                                {!!props.rules &&
                                    props.rules.map(
                                        (item: string, i: number) => (
                                            <p key={`password-rule-${i + 1}`}>
                                                {item}
                                            </p>
                                        )
                                    )}
                            </div>
                        )}
                </div>
            </div>
        </>
    );
});

interface SelectBoxProps {
    name: string;
    id: string;
    onChange: (e: any) => void;
    value: string;
    className: string;
    label: string;
    options: any;
    validationRules?: any;
    error?: { status: boolean; message: string };
    testId?: string;
    disabled?: boolean;
}
export const SelectBox = (props: SelectBoxProps) => {
    const errStatus = props.error && props.error.status;
    const disabledProp = props.disabled ? { disabled: true } : {};
    const disabledClass = props.disabled ? ' disabled' : '';
    const appConfig = new AppConfigurationService();
    const currentRegionCountry = appConfig?.getRegionCode();
    const isSyndicated =
        SYNDICATED_HEADER_COUNTRIES.includes(currentRegionCountry);
    return (
        <>
            <div className="form-field select-container">
                <div className="form-field__eyebrow">
                    <label htmlFor={props.id} className="form-field__label">
                        {props.label}
                    </label>

                    {errStatus && (
                        <img
                            src={errorNoticeIcon}
                            className="user-input-error-notice"
                            alt={props.error.message}
                            data-testid="warningIcon"
                        />
                    )}
                </div>

                <select
                    name={props.name}
                    id={props.name}
                    onChange={props.onChange}
                    value={props.value}
                    className={`fmc-select${disabledClass} ${
                        errStatus ? 'fmc-input--error' : ''
                    } ${props.className}${
                        isSyndicated ? ' syndicated-input' : ''
                    }`}
                    aria-label={props.label}
                    data-validation-rules={props.validationRules || []}
                    data-label={props.label}
                    data-testid={props.testId}
                    {...props.validationRules}
                    {...disabledProp}
                >
                    {props.options?.length > 0 &&
                        props.options.map((option, index: number) => (
                            <option
                                value={option.value}
                                aria-label={
                                    option.ariaLabel || option.displayName
                                }
                                key={`${option.value}-${index}`}
                                selected={option.value === props.value}
                                data-testid="dropdown-option"
                            >
                                {option.displayName}
                            </option>
                        ))}
                </select>
                <div className="form-field-error">
                    {errStatus && (
                        <span className="error-text">
                            {props.error.message}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

export interface Option {
    displayName: string;
    value: string;
    isChecked: boolean;
    name?: string; // if name and id not given then value used as both field name and id (value + option index)
    id?: string; // should pass unique id for each option, otherwise check will not work
    showTooltip?: boolean;
}

export interface CheckboxProps {
    options: Option[];
    handleChange: (options: Option[]) => void; // updated value will be passed as param
    labelClassName?: string; // class name for styling the checkbox display name (i.e label)
    hasTooltip?: boolean;
    tooltipPreferenceOptions?: PreferenceOption[];
    isLincoln?: boolean;
    setOptions?: Dispatch<SetStateAction<Option[]>>;
    isMobile?: boolean;
    tooltipAriaLabel?: string;
}

export const Checkbox = (props: CheckboxProps): ReactElement => {
    const { brand } = new AppConfigurationService();
    const [options, updateOptions] = useState(props.options);
    const brandColor: string = props.isLincoln ? '#F26147' : '#066FEF';
    const tooltipIsOpen: boolean = options?.some(
        (option) => option.showTooltip
    );

    const handleChange = (event: any, index: number) => {
        const updatedOptions = [...options];
        updatedOptions[index].isChecked = !!event.target.checked;
        updateOptions(updatedOptions);
        props.handleChange && props.handleChange(updatedOptions);
    };

    const toggleTooltip = (index: number, isVisible: boolean): void => {
        const updatedOptions = [...options];
        const newOptions = updatedOptions.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    showTooltip: isVisible,
                };
            }
            return item;
        });
        updateOptions(newOptions);
        props.handleChange && props.handleChange(newOptions);
    };

    useEffect(() => {
        if (tooltipIsOpen && props.isMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [tooltipIsOpen, props.isMobile]);

    return (
        <div>
            {options.map((option, index) => {
                const name = option.name || `${option.value}-${index}`;
                const id = option.id || `${option.value}-${index}`;
                const changeIconLayer: boolean =
                    props.hasTooltip && index === 2 && options[1].showTooltip;

                return (
                    <div
                        key={option.displayName}
                        className={
                            props.hasTooltip
                                ? 'checkbox-with-tooltip-container'
                                : ''
                        }
                    >
                        <div key={`${name}-${index}`} className="checkbox_ctn">
                            <fieldset className="fmc-check">
                                <label htmlFor={id}>
                                    <input
                                        type="checkbox"
                                        id={id}
                                        data-testid={id}
                                        name={name}
                                        value={option.value}
                                        checked={option.isChecked}
                                        onChange={(event) =>
                                            handleChange(event, index)
                                        }
                                    />
                                    <span className={props?.labelClassName}>
                                        {option.displayName}
                                    </span>
                                </label>
                            </fieldset>
                        </div>
                        {props.hasTooltip && (
                            <Tooltip
                                content={
                                    <CommunicationCenterTooltip
                                        preferenceOption={
                                            props.tooltipPreferenceOptions[
                                                index
                                            ]
                                        }
                                    />
                                }
                                toggleCommunicationTooltip={(value) => {
                                    toggleTooltip(index, value);
                                }}
                                showTooltip={option.showTooltip}
                                isFord={brand === 'ford'}
                                isLincoln={brand === 'lincoln'}
                                tooltipPreferenceOption={
                                    props.tooltipPreferenceOptions[index]
                                }
                                onIconClick={() => {
                                    toggleTooltip(index, !option.showTooltip);
                                }}
                                isMobile={props.isMobile}
                                tooltipAriaLabel={props.tooltipAriaLabel}
                                isFromCommunication
                            >
                                <div
                                    className={`tooltip-icon ${
                                        option.showTooltip ? 'open' : ''
                                    } ${
                                        changeIconLayer
                                            ? 'change-icon-layers'
                                            : ''
                                    }`}
                                >
                                    <button
                                        tabIndex={0}
                                        disabled={changeIconLayer}
                                        aria-label={props.tooltipAriaLabel}
                                    >
                                        <TooltipIcon
                                            color={brandColor}
                                            dataTestId="tooltip-icon"
                                        />
                                    </button>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export interface RadioButtonOption {
    displayName: string;
    value: any;
    name?: string; // if name and id not given then value used as both field name and id (value + option index)
    id?: string; // should pass unique id for each option, otherwise check will not work
}

interface RadioButtonProps {
    RadioButtonOptions: RadioButtonOption[];
    handleChange: (RadioButtonOptions: RadioButtonOption[]) => void; // updateed value will be passed as param
    defaultCheckedOption: any;
    labelClassName?: string; // class name for styling the checkbox display name (i.e label)
    isHorizontal?: boolean;
    radioGroupName?: string;
    radioGroupNameClass?: string;
}

export const RadioButtonGroup = (props: RadioButtonProps): ReactElement => {
    const [isButtonChecked, updateIsButtonChecked] = useState(
        props.defaultCheckedOption
    );
    const radioButtons = props.RadioButtonOptions;
    const handleChange = (event: any) => {
        let checkedRadioOption;
        if (event.target.checked) {
            checkedRadioOption = event.target.value;
            updateIsButtonChecked(checkedRadioOption);
        }
        props.handleChange && props.handleChange(checkedRadioOption);
    };

    useEffect(() => {
        updateIsButtonChecked(props.defaultCheckedOption);
    }, [props.defaultCheckedOption]);
    return (
        <div>
            <fieldset
                className={`fmc-check${
                    props.isHorizontal ? ' fmc-check--horizontal' : ''
                }`}
            >
                <legend className={props?.radioGroupNameClass || ''}>
                    {props?.radioGroupName}
                </legend>
                {radioButtons.map((radioButton, index) => {
                    const name =
                        radioButton.name || `${radioButton.value}-${index}`;
                    const id =
                        radioButton.id || `${radioButton.value}-${index}`;
                    return (
                        <label key={`${name}-${radioButton.value}`}>
                            <input
                                aria-label={radioButton.value}
                                type="radio"
                                id={id}
                                name={name}
                                value={radioButton.value}
                                data-testid={`form-field-radio-${name}`}
                                checked={radioButton.value == isButtonChecked}
                                onChange={(event) => handleChange(event)}
                            />
                            <span>{radioButton.displayName}</span>
                        </label>
                    );
                })}
            </fieldset>
        </div>
    );
};
