import React, { ReactElement } from 'react';

import './tertiary-button.scss';
import SecondaryButton from '@common/secondary-button/secondary-button';
import TertiaryButtonChevron, {
    ChevronDirection,
} from '@common/tertiary-button/tertiary-button-chevron/tertiary-button-chevron';
import { LinkTargetValues } from '@constants';
import PlusSignIcon from '@common/plus-sign-icon/plus-sign-icon';

interface Props {
    brand?: string;
    labelText: string;
    link?: string;
    linkTarget?: LinkTargetValues;
    onClick?: () => void;
    ariaLabel?: string;
    internal: boolean;
    dataTestId?: string;
    chevronDirection?: ChevronDirection;
    plusSign?: boolean;
    fromOrderHistory?: boolean;
}
const TertiaryButton = (props: Props): ReactElement => {
    const isLincoln: boolean = props.brand === 'lincoln';

    const generateClassName = (): string => {
        let className = '';

        if (isLincoln) {
            className = 'fmc-text-button fmc-text-button--chevron-left';

            if (props.fromOrderHistory) {
                className = '';
            }
        }

        return className;
    };

    return (
        <>
            {props.labelText && (
                <div className="tertiary-button" data-testid="tertiary-button">
                    <SecondaryButton
                        className={generateClassName()}
                        internal={props.internal}
                        onClick={props.onClick}
                        link={props.link}
                        linkTarget={props.linkTarget}
                        buttonType={isLincoln ? 'chevron-left' : 'dark-left'}
                        aria-label={props.ariaLabel}
                        dataTestId={props.dataTestId}
                    >
                        {props.chevronDirection === 'left' && (
                            <TertiaryButtonChevron
                                direction={props.chevronDirection}
                            />
                        )}
                        <span className="tertiary-button__label-text">
                            {props.labelText}
                        </span>
                        {props.chevronDirection === 'right' && (
                            <TertiaryButtonChevron
                                direction={props.chevronDirection}
                                color={isLincoln ? '#485b65' : '#0562d2'}
                            />
                        )}
                        {props.plusSign && (
                            <PlusSignIcon
                                color={isLincoln ? '#485b65' : '#0562d2'}
                            />
                        )}
                    </SecondaryButton>
                </div>
            )}
        </>
    );
};

export default TertiaryButton;
