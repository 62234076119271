import React, { FormEvent, useEffect, useState } from 'react';
import AutoComplete from '@own/accessible-autocomplete/react';
import { useHelpCardsContent } from '@views/page-not-found-view/hooks/use-help-cards-content';
import AstuteService from '@services/astute-service/astute-service';
import { useSearchContent } from './hooks/use-search-content';
import { HeroTiles, ViewBanner } from '../';
import './search-bar.scss';
import './Autocomplete.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { GTM_EVENTS, TABLET_BREAKPOINT } from '@services/support-constants';
import { pushEvent } from '@services/astute-service/push-event';
import { SelfHelpButtons } from '@sections/account-portal/components/self-help-buttons/self-help-buttons';

const searchIcon = './icons/search-icon.svg';

interface AutocompleteProps {
    id: string;
    source: (
        currentSearchTerm: string,
        syncResults: (string: any) => void
    ) => Promise<void>;
    placeholder: string;
    onConfirm: (searchTerm: string) => void;
    experimentalAllowAnyInput: boolean;
    defaultValue: string;
    ariaLabelInput: string;
    ariaLabelUl: string;
    tNoResults: () => string;
}

const SearchBarContent = () => {
    const [sessionID, setSessionID] = useState('');
    const [idPostFix, setIdPostFix] = useState('');
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();
    const isSearchOnly = helpCardsContent.hide ? 'search-only' : '';
    const isHelpCardsOnly = searchContent.hide ? 'help-cards-only' : '';
    const astuteService = new AstuteService();
    const appConfig = new AppConfigurationService();
    const euClass = appConfig.isEUCountry() ? 'search-eu' : '';
    const isFord = appConfig.brand === 'ford';
    const isLincoln = appConfig.brand === 'lincoln';

    const redirectToAstuteSearch = (searchTerm: string) => {
        if (searchTerm) {
            const searchBox = document.getElementById(
                `search-bar-${idPostFix}`
            ) as HTMLInputElement;
            searchBox.value = searchTerm;
            pushEvent(GTM_EVENTS.PREDICTIVE_SEARCH);
            const target = searchContent.ctaTargetBlank ? '_blank' : '_self';

            window.open(searchContent?.searchLink + searchTerm, target);
        }
    };

    const search = (event: FormEvent) => {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        const inputValue = currentTarget
            ?.getElementsByTagName('input')
            ?.item(0)?.value;
        redirectToAstuteSearch(inputValue);
    };

    const initializeAutocompleteSession = async () => {
        setSessionID(
            await astuteService.getSessionIdForSearch(
                appConfig.currentLanguageRegionCapitalizedCode,
                appConfig.brand
            )
        );
    };

    const suggest = async (
        currentSearchTerm: string,
        syncResults: (string) => void
    ) => {
        const suggestions =
            (await astuteService.getSuggestedResults(
                currentSearchTerm,
                sessionID,
                appConfig.brand
            )) || [];
        syncResults(suggestions);
    };

    useEffect(() => {
        (async () => await initializeAutocompleteSession())();

        window.innerWidth <= TABLET_BREAKPOINT
            ? setIdPostFix('mobile')
            : setIdPostFix('desktop');
    }, []);

    const autocompleteProps: AutocompleteProps = {
        id: `search-bar-${idPostFix}`,
        source: suggest,
        defaultValue: '',
        placeholder: isLincoln ? searchContent?.searchBarHelperText || '' : '',
        onConfirm: redirectToAstuteSearch,
        experimentalAllowAnyInput: true,
        ariaLabelInput: searchContent?.searchBarAriaLabel,
        ariaLabelUl: searchContent?.suggestedResultsListAriaLabel,
        tNoResults: () => searchContent?.noResultsText || '',
    };

    return (
        <div
            className={`search-container ${isSearchOnly} ${isHelpCardsOnly} ${euClass}`}
        >
            <h2
                className={`${
                    appConfig.brand === 'ford'
                        ? 'fmc-type--heading1'
                        : 'search-title fds-color__text--white'
                }`}
            >
                {searchContent.searchTitle}
            </h2>

            {isFord && searchContent.searchBodyText && (
                <p className="self-help-body">{searchContent.searchBodyText}</p>
            )}

            {!searchContent.hide && (
                <form
                    onSubmit={search}
                    data-testid="search-form"
                    role="search"
                    action="."
                    className={`${
                        isLincoln ? 'search-form' : 'self-help-search'
                    }`}
                >
                    <span className="placeholder-text-offset" />
                    <AutoComplete {...autocompleteProps} />
                    <button
                        aria-label={searchContent?.submitSearchAriaLabel}
                        className="search-icon"
                    >
                        {appConfig.brand === 'ford' && (
                            <img
                                src={searchIcon}
                                alt=""
                                loading="lazy"
                                fetchPriority="low"
                            />
                        )}
                        {appConfig.brand === 'lincoln' && (
                            <img
                                src="./icons/search.svg"
                                alt=""
                                loading="lazy"
                                fetchPriority="low"
                            />
                        )}
                    </button>
                </form>
            )}
        </div>
    );
};

export const SearchBar = () => {
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();
    const justifyContent =
        helpCardsContent.hide || searchContent.hide
            ? 'justify-vehicle-tabs'
            : '';
    const searchOnly =
        helpCardsContent.hide && !searchContent.hide ? 'search-banner' : '';
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';

    return (
        <>
            {(!searchContent.hide || !helpCardsContent.hide) &&
                (isLincoln ? (
                    <div className={`search-bar ${searchOnly}`} id="SH">
                        <ViewBanner
                            topMasthead={<SearchBarContent />}
                            bottomComponent={
                                <HeroTiles
                                    helpCardsContent={helpCardsContent}
                                />
                            }
                            backgroundImageDesktop={
                                process.env.REACT_APP_AEM_BASE_URL +
                                searchContent.searchBackgroundImageDesktop
                            }
                            backgroundImageMobile={
                                process.env.REACT_APP_AEM_BASE_URL +
                                searchContent.searchBackgroundImageMobile
                            }
                            addClass={justifyContent}
                        />
                    </div>
                ) : (
                    <div
                        className="self-help-container"
                        data-testid="self-help-container"
                    >
                        <ViewBanner
                            topMasthead={<SearchBarContent />}
                            bottomComponent={
                                <SelfHelpButtons
                                    helpCardsContent={helpCardsContent}
                                />
                            }
                            addClass={justifyContent}
                        />
                    </div>
                ))}
        </>
    );
};
