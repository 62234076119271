import { DSL_API_PATH, DSL_BASE_URL, SESSION_KEY_API_CACHE } from '@constants';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import ServerSideService from '../server-side-service/server-side-service';

export interface CacheEntry {
    key: string;
    data: any;
    expires?: number;
}

export enum STORE_TYPE {
    SESSION,
    LOCAL,
}

const CACHING_ENABLED = true;
const CACHE_EVICT_IN_MILLIS = 1 * 60 * 60 * 1000; // hours * minutes * seconds * millis

export class CacheService {
    private store: Storage | undefined;
    DEFAULT_STORE: STORE_TYPE = STORE_TYPE.SESSION;

    constructor(type?: STORE_TYPE) {
        if (ServerSideService.isClientSide()) {
            type && (this.DEFAULT_STORE = type);
            this.store =
                this.DEFAULT_STORE === STORE_TYPE.SESSION
                    ? sessionStorage
                    : localStorage;
        }
    }

    private getOrCreateCache(cacheName: string) {
        if (this.store) {
            if (!this.store.getItem(cacheName)) {
                this.store.setItem(cacheName, JSON.stringify({}));
            }

            const apiCache = this.store.getItem(cacheName);
            return apiCache ? JSON.parse(apiCache) : {};
        }
    }

    public putInCache(
        cacheName: string,
        cacheEntry: CacheEntry,
        ttl?: number
    ): void {
        if (this.store && CACHING_ENABLED) {
            const { key, data } = cacheEntry;
            const created = new Date().getTime();
            const expires = cacheEntry.expires
                ? cacheEntry.expires
                : new Date().getTime() + (ttl || CACHE_EVICT_IN_MILLIS);
            const cache = this.getOrCreateCache(cacheName);
            cache[key] = {
                data,
                created,
                expires,
            };
            this.store.setItem(cacheName, JSON.stringify(cache));
        }
    }

    public getFromCache(
        cacheName: string,
        key: string,
        ttl?: number
    ): any | undefined {
        if (this.store) {
            const cacheEntry = this.getOrCreateCache(cacheName)[key];
            if (cacheEntry) {
                const epochInPast =
                    new Date().getTime() - (ttl || CACHE_EVICT_IN_MILLIS);
                if (cacheEntry.expires > epochInPast) return cacheEntry.data;
                else {
                    this.evictFromCache(cacheName, key);
                    return undefined;
                }
            } else {
                return undefined;
            }
        }
    }

    public evictFromCache(cacheName: string, key: string): void {
        if (this.store) {
            const cache = this.getOrCreateCache(cacheName);
            delete cache[key];
            this.store.setItem(cacheName, JSON.stringify(cache));
        }
    }

    public evictProfileServiceCache() {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const profileServiceURL = `${dslUrl}${DSL_API_PATH.PROFILE_WITH_VEHICLES}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`;
        this.evictFromCache(SESSION_KEY_API_CACHE, profileServiceURL);
    }

    public evictProfileLiteCache() {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const profileLiteURL = `${dslUrl}${DSL_API_PATH.PROFILE_LITE}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`;
        this.evictFromCache(SESSION_KEY_API_CACHE, profileLiteURL);
    }

    public evictBingServiceCache() {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const bingServiceURL = `${dslUrl}${DSL_API_PATH.BING_DEALERS}?client=myford&ovtype=2&count=1f_dlrpa=`;
        this.evictFromCache(SESSION_KEY_API_CACHE, bingServiceURL);
    }

    public evictPreferredDealerCache() {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const preferredDealerUrl = `${dslUrl}${DSL_API_PATH.PREFERRED_DEALER}`;
        this.evictFromCache(SESSION_KEY_API_CACHE, preferredDealerUrl);
    }

    public evictAllOmsCaches() {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const omsUrl = `${dslUrl}/oms/api/`;

        const apiCache = this.getOrCreateCache(SESSION_KEY_API_CACHE);

        for (const key in apiCache) {
            if (key.includes(omsUrl)) {
                this.evictFromCache(SESSION_KEY_API_CACHE, key);
            }
        }
    }
}
