import { AxiosRequestConfig } from 'axios';
import { DSL_BASE_URL } from '@constants';
import HttpService from '../../http-service/http-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { HttpsProxyAgent } from 'https-proxy-agent';

interface OSBAPIRequest<T> {
    endpointUrl: string;
    requestParameters: T;
    isCacheable?: boolean;
    hasMarketConfig?: boolean;
    hasParamSerilizer?: boolean;
    callback?: { (progressPercent: number): void; (arg0: number): void };
}
const getProxyAgent = () => {
    const proxyAgent = new HttpsProxyAgent({
        host: 'internet.ford.com',
        port: 83,
    });
    return proxyAgent;
};
export default class OsbServiceAdapter {
    get = async <P, R>(_requestParams: OSBAPIRequest<P>): Promise<R> => {
        let axiosRequestConfig: AxiosRequestConfig = {
            params: _requestParams.requestParameters,
            proxy: false,
            httpAgent: getProxyAgent(),
        };

        if (_requestParams.hasParamSerilizer) {
            const qs = require('qs');
            axiosRequestConfig = {
                ...axiosRequestConfig,
                paramsSerializer: (params: any) => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat',
                    });
                },
            };
        }

        const url = `${DSL_BASE_URL}${_requestParams.endpointUrl}`;
        return HttpService.get<R>(
            url,
            _requestParams.isCacheable || false,
            axiosRequestConfig
        ).then((response) => {
            return response.data;
        });
    };

    post = async <P, R>(_requestParams: OSBAPIRequest<P>): Promise<R> => {
        const axiosRequestConfig: AxiosRequestConfig = {
            proxy: false,
            httpAgent: getProxyAgent(),
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent >= 100) {
                    percent = 99;
                }
                if (percent > 10) {
                    _requestParams.callback && _requestParams.callback(percent);
                }
            },
        };
        const appConfig = new AppConfigurationService().getAppConfiguration();
        let data: P = _requestParams.requestParameters;

        if (_requestParams.hasMarketConfig) {
            const marketConf = {
                locale: appConfig.languageRegionCode,
            };

            data = { ...marketConf, ...data };
        }

        const url = `${DSL_BASE_URL}${_requestParams.endpointUrl}`;

        return HttpService.post<R>(url, data, axiosRequestConfig).then(
            (response) => {
                return response.data;
            }
        );
    };

    put = async <P, R>(_requestParams: OSBAPIRequest<P>): Promise<R> => {
        const axiosRequestConfig: AxiosRequestConfig = {
            proxy: false,
            httpAgent: getProxyAgent(),
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent >= 100) {
                    percent = 99;
                }
                if (percent > 10) {
                    _requestParams.callback && _requestParams.callback(percent);
                }
            },
        };
        const appConfig = new AppConfigurationService().getAppConfiguration();
        let data: P = _requestParams.requestParameters;

        if (_requestParams.hasMarketConfig) {
            const marketConf = {
                locale: appConfig.languageRegionCode,
            };

            data = { ...marketConf, ...data };
        }

        const url = `${DSL_BASE_URL}${_requestParams.endpointUrl}`;
        return HttpService.put<R>(url, data, axiosRequestConfig).then(
            (response) => {
                return response.data;
            }
        );
    };

    delete = async <P, R>(_requestParams: OSBAPIRequest<P>): Promise<R> => {
        const url = `${DSL_BASE_URL}${_requestParams.endpointUrl}`;

        let axiosRequestConfig: AxiosRequestConfig = {
            proxy: false,
            httpAgent: getProxyAgent(),
        };

        if (this.hasBookingAuthorization(_requestParams.requestParameters)) {
            axiosRequestConfig = {
                ...axiosRequestConfig,
                headers: {
                    identifier:
                        _requestParams.requestParameters.bookingAuthorization
                            .identifier,
                    'Access-Code':
                        _requestParams.requestParameters.bookingAuthorization
                            .accessCode,
                },
            };
        } else {
            axiosRequestConfig = {
                ...axiosRequestConfig,
                params: _requestParams.requestParameters,
            };
        }

        return HttpService.delete(url, axiosRequestConfig).then((response) => {
            return response.data;
        });
    };

    private hasBookingAuthorization(obj: any): obj is {
        bookingAuthorization: { identifier: string; accessCode: string };
    } {
        return (
            obj.bookingAuthorization &&
            typeof obj.bookingAuthorization === 'object' &&
            'identifier' in obj.bookingAuthorization &&
            'accessCode' in obj.bookingAuthorization
        );
    }
}
