import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { DataLayerService } from '../data-layer-service/data-layer-service';
import { LogService } from '../log-service/log-service';
import ConsumerIdService from '../consumerId-service/consumerId-service';
import ProfileService from '../profile-service/profile-service';
import CheckExperienceType, {
    DeviceType,
} from '../check-device-type/check-experience-type';
import { COOKIE_CONFIGURATION } from '@constants';

export interface ShortcodeProviders {
    ymm?: VehicleAttributes | Promise<VehicleAttributes>;
    nameplate?: string;
    gdprModalButtonPressed?: GdprModalButtonType | Promise<GdprModalButtonType>;
    tileOnClickCtaInfo?: TileOnClickCtaInfo;
    referredUrlPageName?: string;
    dyfComponentName?: string;
    orderType?: string;
    addVehicleStatus?: string;
    languageRegionCode?: string;
    category?: string;
    cvotOrderStatus?: string;
    fprButton?: string;
    mfaToggle?: string;
    mfaModal?: string;
    consentSelection?: string;
    secondaryNavCategoryName?: string;
    secondaryNavLinkName?: string;
}
export type TileOnClickCtaInfo = {
    tileName?: string;
    ctaName?: string;
    referredTo?: string;
};

export interface ShortcodeParts {
    raw: string;
    name: string;
    parameters: ShortcodeParameter[];
}

interface ShortcodeParameter {
    name: string;
    value: string;
}

export type GdprModalButtonType = 'Accept' | 'Manage';

export class ShortcodeService {
    public constructor(private shortcodeProviders?: ShortcodeProviders) {}

    public async processShortcodes(value: string): Promise<string> {
        const shortcodes: ShortcodeParts[] = this.parseForShortcodes(value);
        return this.injectShortcodeValues(value, shortcodes);
    }

    private parseForShortcodes(value: string): ShortcodeParts[] {
        const shortcodes: ShortcodeParts[] = [];
        try {
            const rawShortcodes = this.getShortcodeMatches(value);
            if (rawShortcodes) {
                rawShortcodes.forEach((rawShortcode: string) => {
                    const shortcodeName = rawShortcode
                        .split(' ')[0]
                        .substr(1)
                        .replace(']', '');
                    const parameterMatches =
                        this.getParameterMatches(rawShortcode);
                    let parameters: ShortcodeParameter[] = [];
                    parameters = parameterMatches.map(
                        (parameterUnparse: string) => {
                            const name: string =
                                this.getParameterName(parameterUnparse);
                            const value =
                                this.getParameterValue(parameterUnparse);
                            return {
                                name: name,
                                value: value,
                            };
                        }
                    );
                    const shortcodeParts: ShortcodeParts = {
                        raw: rawShortcode,
                        name: shortcodeName,
                        parameters: parameters,
                    };
                    shortcodes.push(shortcodeParts);
                });
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                'There was an error parsing for shortcodes. Reason: ',
                e.message
            );
        }
        return shortcodes;
    }

    private getShortcodeMatches(value: string): string[] {
        const matches: string[] = [];
        const remaining = value;
        let cursor = 0;
        try {
            while (remaining.indexOf('[', cursor) >= 0) {
                const openingBracketIndex = remaining.indexOf('[', cursor);
                if (remaining[openingBracketIndex - 1] !== '\\') {
                    if (remaining.indexOf(']', cursor) >= 0) {
                        while (remaining.indexOf(']', cursor) >= 0) {
                            const closingBracketIndex = remaining.indexOf(
                                ']',
                                cursor
                            );
                            if (
                                closingBracketIndex >= openingBracketIndex &&
                                remaining[closingBracketIndex - 1] !== '\\'
                            ) {
                                cursor = closingBracketIndex + 1;
                                matches.push(
                                    remaining.substring(
                                        openingBracketIndex,
                                        closingBracketIndex + 1
                                    )
                                );
                                break;
                            } else {
                                cursor = closingBracketIndex + 1;
                            }
                        }
                    } else {
                        throw new Error(
                            `No closing bracket for opening bracket at char ${openingBracketIndex}.`
                        );
                    }
                } else {
                    cursor = openingBracketIndex + 1;
                }
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                `Any error occured while trying to parse ${value} for shortcodes. Reason: ${e.message}`
            );
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterMatches(value: string): string[] {
        let cursor = 0;
        const matches: string[] = [];
        while (value.indexOf(` `, cursor) >= 0) {
            if (value.indexOf(` `, cursor) < value.indexOf(`='`, cursor)) {
                const startIndex = value.indexOf(` `, cursor);
                const middleIndex = value.indexOf(`='`, cursor);
                cursor = middleIndex + 2;
                if (value.indexOf(`'`, cursor) >= 0) {
                    while (value.indexOf(`'`, cursor) >= 0) {
                        if (value[value.indexOf(`'`, cursor) - 1] !== '\\') {
                            const endIndex = value.indexOf(`'`, cursor);
                            cursor = endIndex + 1;
                            matches.push(
                                value.substring(startIndex + 1, endIndex + 1)
                            );
                            break;
                        } else {
                            cursor = value.indexOf(`'`, cursor) + 1;
                        }
                    }
                } else {
                    throw new Error(
                        `No closing quotation mark for parameter starting at char ${startIndex}`
                    );
                }
            } else {
                cursor = value.indexOf(` `, cursor);
            }
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterName(value: string): string {
        return value.split('=')[0].trim();
    }

    private getParameterValue(value: string): string {
        const cleanedParameterValue = value
            .split('=')[1]
            .replace("\\'", "'")
            .replace('\\[', '[')
            .replace('\\]', ']')
            .replace('\\\\', '\\');
        return cleanedParameterValue.substr(
            1,
            cleanedParameterValue.length - 2
        );
    }

    private async injectShortcodeValues(
        value: string,
        shortcodes: ShortcodeParts[]
    ): Promise<string> {
        let processed: string = value;
        await ShortcodeService.asyncForEach(
            shortcodes,
            async (shortcode: ShortcodeParts) => {
                const shortcodeValue: string = await this.getShortcodeValue(
                    shortcode
                );
                if (shortcodeValue) {
                    processed = processed.replace(
                        shortcode.raw,
                        shortcodeValue
                    );
                }
            }
        );
        return processed;
    }

    private async getShortcodeValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        switch (shortcode.name) {
            case 'user-language':
                return this.getUserLanguageValue();
            case 'rad-ui-version':
                return this.getRadUiVersionValue();
            case 'selected-vehicle-year':
                return this.getSelectedVehicleYearValue(shortcode);
            case 'nameplate':
                return this.getNameplateValue(shortcode);
            case 'selected-vehicle-make':
                return this.getSelectedVehicleMakeValue(shortcode);
            case 'selected-vehicle-model':
                return this.getSelectedVehicleModelValue(shortcode);
            case 'selected-vehicle-delivery-status':
                return this.getSelectedVehicleDeliveryStatusValue(shortcode);
            case 'login-status':
                return this.getLoginStatusValue();
            case 'registered-status':
                return this.getRegisteredStatusValue();
            case 'user-guid':
                return this.getUserGuidValue();
            case 'country-code':
                return this.getCountryCodeValue();
            case 'selected-country':
                return this.getSelectedCountryValue();
            case 'order-type':
                return this.getOrderTypeValue();
            case 'add-vehicle-status':
                return this.getAddVehicleStatusValue();
            case 'customer-id':
                return this.getCustomerIDValue();
            case 'gdpr-opt-status':
                return this.getGdprOptStatusValue(shortcode);
            case 'from-datalayer':
                return this.getFromDatalayerValue(shortcode);
            case 'gdpr-modal-button-pressed':
                return this.getGdprModalButtonPressedValue(shortcode);
            case 'tile-name':
                return this.getTileNameValue(shortcode);
            case 'cta-name':
                return this.getCtaNameValue(shortcode);
            case 'component-name':
                return this.getComponentNameValue(shortcode);
            case 'referred-url-page-name':
                return this.getReferredUrlPageNameValue();
            case 'experience-type':
                return this.getExperienceTypeValue();
            case 'env-domain':
                return this.getEnvDomainValue();
            case 'order-status':
                return this.getOrderStatusValue(shortcode);
            case 'fpr-button':
                return this.getFprButtonValue(shortcode);
            case 'mfa-toggle':
                return this.getMfaToggleValue(shortcode);
            case 'mfa-modal-selection':
                return this.getMfaModalSelectionValue(shortcode);
            case 'category':
                return this.getCategoryValue(shortcode);
            case 'consent-selection':
                return this.getConsentSelectionValue(shortcode);
            case 'secondary-nav-category-name':
                return this.getSecondaryNavCategoryNameValue(shortcode);
            case 'secondary-nav-link-name':
                return this.getSecondaryNavLinkNameValue(shortcode);
            default:
                throw new Error(
                    `Shortcode ${shortcode} not recognized. A value cannot be provided.`
                );
        }
    }

    private getUserLanguageValue(): string {
        const languageMap: any = {};
        languageMap['English'] = 'eng';
        languageMap['Français'] = 'fre';
        languageMap['German'] = 'due';
        languageMap['Czech'] = 'cze';
        languageMap['Italian'] = 'ita';
        languageMap['Dutch'] = 'dut';
        languageMap['Turkish'] = 'tur';
        languageMap['Russian'] = 'rus';
        languageMap['Danish'] = 'dan';
        languageMap['Swedish'] = 'swe';
        languageMap['Polish'] = 'pol';
        languageMap['Greek'] = 'gre';
        languageMap['Rundi'] = 'run';
        languageMap['Norwegian'] = 'nob';
        languageMap['Finnish'] = 'fin';
        languageMap['Hungarian'] = 'hun';
        languageMap['Spanish'] = 'spa';
        languageMap['Portuguese'] = 'por';
        languageMap['Slovak'] = 'slo';

        const currentLanguage = new AppConfigurationService().currentLanguage;
        if (languageMap[currentLanguage]) {
            return languageMap[currentLanguage];
        } else {
            throw new Error(
                `Not able to return three letter language code for ${currentLanguage}. Logic for shortcode [user-language] needs to be updated.`
            );
        }
    }

    private getRadUiVersionValue(): string {
        const width = document.body.clientWidth;
        if (width > 992) {
            return 'ui:rad:pc';
        } else if (width > 768) {
            return 'ui:rad:tablet';
        } else {
            return 'ui:rad:mobile';
        }
    }

    private async getSelectedVehicleYearValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        if (this.shortcodeProviders?.ymm) {
            const ymm: VehicleAttributes = await Promise.resolve(
                this.shortcodeProviders.ymm
            );
            return `${ymm.year}`;
        } else {
            throw new Error(
                `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
            );
        }
    }

    private getNameplateValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.nameplate) {
            return this.shortcodeProviders.nameplate;
        } else {
            throw new Error(`Nameplate unavailable for chosen vehicle`);
        }
    }

    private async getSelectedVehicleMakeValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        if (this.shortcodeProviders?.ymm) {
            const ymm: VehicleAttributes = await Promise.resolve(
                this.shortcodeProviders.ymm
            );
            return `${ymm.make}`.toLowerCase();
        } else {
            throw new Error(
                `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
            );
        }
    }

    private async getSelectedVehicleModelValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        if (this.shortcodeProviders?.ymm) {
            const ymm: VehicleAttributes = await Promise.resolve(
                this.shortcodeProviders.ymm
            );
            return `${ymm.model}`.toLowerCase();
        } else {
            throw new Error(
                `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
            );
        }
    }

    private async getSelectedVehicleDeliveryStatusValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        if (this.shortcodeProviders?.ymm) {
            const ymm: VehicleAttributes = await Promise.resolve(
                this.shortcodeProviders.ymm
            );
            return `${ymm.ownerState === 0 ? 'pre' : 'post'}`;
        } else {
            throw new Error(
                `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
            );
        }
    }

    private async getLoginStatusValue(): Promise<string> {
        const authenticationService = new AuthenticationService();
        return (await authenticationService.onIsAuthenticated())
            ? 'logged in'
            : 'logged out';
    }

    private async getRegisteredStatusValue(): Promise<string> {
        const authenticationService = new AuthenticationService();
        if (await authenticationService.onIsAuthenticated()) {
            return 'registered';
        } else {
            throw new Error(
                'Cannot return registered status for unauthenticated user.'
            );
        }
    }

    private async getUserGuidValue(): Promise<string> {
        const profile =
            (await new AuthenticationService().onIsAuthenticated()) &&
            (await new ProfileService().request());
        if (profile) {
            return profile.profile.userGuid.toUpperCase();
        } else {
            throw new Error(
                'Cannot return user guid. There was an issue getting the profile.'
            );
        }
    }

    private getCountryCodeValue(): string {
        const currentCountryCode = new AppConfigurationService()
            .currentCountryCode;
        return currentCountryCode;
    }

    private getSelectedCountryValue(): string {
        return this.shortcodeProviders.languageRegionCode;
    }

    private getOrderTypeValue(): string {
        return this.shortcodeProviders.orderType;
    }

    private getAddVehicleStatusValue(): string {
        return this.shortcodeProviders.addVehicleStatus;
    }

    private async getCustomerIDValue(): Promise<string> {
        const appConfig = new AppConfigurationService();
        const service = new ProfileService();
        const response: any = await service.request();
        if (
            response?.profile?.country ===
            appConfig.get3LetterCountryCode().toUpperCase()
        ) {
            return await this.getConsumerIdFromService();
        }
        return undefined;
    }

    private getGdprOptStatusValue(shortcode: ShortcodeParts): string {
        const retrievedCookieConfig =
            localStorage.getItem(COOKIE_CONFIGURATION);
        if (retrievedCookieConfig) {
            const cookieSettings = JSON.parse(retrievedCookieConfig).value;
            const analyticSettings: string[] = [];
            for (const key in cookieSettings) {
                const value = cookieSettings[key];
                const analyticValue = value ? 'yes' : 'no';
                analyticSettings.push(`${key}:${analyticValue}`);
            }
            return analyticSettings.join('|');
        } else if (shortcode.parameters.length > 0) {
            return shortcode.parameters
                .map((p) => `${p.name.replace('-', ' ')}:${p.value}`)
                .join('|');
        } else {
            throw new Error(
                'Cannot return opt in status. GDPR user preferences not found.'
            );
        }
    }

    private getFromDatalayerValue(shortcode: ShortcodeParts): string {
        const propertyDescriptorParameter = shortcode.parameters.filter(
            (parameter) => parameter.name === 'property-descriptor'
        )[0];
        if (propertyDescriptorParameter) {
            const propertyValue = DataLayerService.getProperty(
                propertyDescriptorParameter.value,
                (window as any).digitaldata
            );
            if (propertyValue) {
                return propertyValue;
            } else {
                throw new Error(
                    `Property "${propertyDescriptorParameter.value}" is not populate in digitaldata.`
                );
            }
        } else {
            throw new Error(
                `Parameter "property-descriptor" needs to be set for this shortcode to populate.`
            );
        }
    }

    private async getGdprModalButtonPressedValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        if (this.shortcodeProviders?.gdprModalButtonPressed) {
            const gdprModalButtonPressed = await Promise.resolve(
                this.shortcodeProviders?.gdprModalButtonPressed
            );
            const acceptParameter = shortcode.parameters.filter(
                (parameter) => parameter.name === 'accept'
            )[0];
            const manageParameter = shortcode.parameters.filter(
                (parameter) => parameter.name === 'manage'
            )[0];
            if (acceptParameter && manageParameter) {
                if (gdprModalButtonPressed === 'Accept') {
                    return acceptParameter.value;
                } else {
                    return manageParameter.value;
                }
            } else {
                throw new Error(
                    `Both "accept" and "manage" are required properties, one of them is missing.`
                );
            }
        } else {
            throw new Error(
                `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
            );
        }
    }

    private getTileNameValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.tileOnClickCtaInfo) {
            const tileOnClickCtaInfo: TileOnClickCtaInfo =
                this.shortcodeProviders.tileOnClickCtaInfo;
            return `${tileOnClickCtaInfo.tileName}`;
        } else {
            throw new Error(`Could not populate Tile Name`);
        }
    }

    private getCtaNameValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.tileOnClickCtaInfo) {
            const tileOnClickCtaInfo: TileOnClickCtaInfo =
                this.shortcodeProviders.tileOnClickCtaInfo;
            return `${tileOnClickCtaInfo.ctaName}`;
        } else {
            throw new Error(`Could not populate CTA Name`);
        }
    }

    private getComponentNameValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.dyfComponentName) {
            return this.shortcodeProviders?.dyfComponentName;
        } else {
            throw new Error(`Could not populate component Name`);
        }
    }

    private getReferredUrlPageNameValue(): string {
        if (this.shortcodeProviders?.referredUrlPageName) {
            return this.shortcodeProviders?.referredUrlPageName;
        } else {
            throw new Error(`Could not populate referred url page name`);
        }
    }

    private getExperienceTypeValue(): string {
        const expType = CheckExperienceType();
        if (expType === DeviceType.MOBILE) {
            return 'mobile';
        } else if (expType === DeviceType.TABLET) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }

    private getEnvDomainValue(): string {
        const { appConfigurations, currentLanguageRegionCode, brand } =
            new AppConfigurationService();
        const currentAppConfig = appConfigurations.filter(
            (appConfig) =>
                brand == appConfig.brand &&
                appConfig.languageRegionCode == currentLanguageRegionCode
        );
        if (currentAppConfig.length != 0) {
            return currentAppConfig[0].domain != ''
                ? 'https://' + currentAppConfig[0].domain
                : '';
        } else {
            return '';
        }
    }

    private getOrderStatusValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.cvotOrderStatus) {
            return this.shortcodeProviders?.cvotOrderStatus;
        } else {
            throw new Error(`Could not populate order status`);
        }
    }

    private getFprButtonValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.fprButton) {
            return this.shortcodeProviders?.fprButton;
        } else {
            throw new Error(`Could not populate ford pass rewards button`);
        }
    }

    private getMfaToggleValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.mfaToggle) {
            return this.shortcodeProviders?.mfaToggle;
        } else {
            throw new Error(`Could not populate mfa toggle`);
        }
    }

    private getMfaModalSelectionValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.mfaModal) {
            return this.shortcodeProviders?.mfaModal;
        } else {
            throw new Error(`Could not populate mfa modal`);
        }
    }

    private getCategoryValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.category) {
            return this.shortcodeProviders?.category;
        } else {
            throw new Error(`Could not populate category`);
        }
    }

    private getConsentSelectionValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.consentSelection) {
            return this.shortcodeProviders?.consentSelection;
        } else {
            throw new Error(`Could not populate consent selection`);
        }
    }

    private getSecondaryNavCategoryNameValue(
        shortcode: ShortcodeParts
    ): string {
        if (this.shortcodeProviders?.secondaryNavCategoryName) {
            return this.shortcodeProviders?.secondaryNavCategoryName;
        } else {
            throw new Error(`Could not populate secondary nav category`);
        }
    }

    private getSecondaryNavLinkNameValue(shortcode: ShortcodeParts): string {
        if (this.shortcodeProviders?.secondaryNavLinkName) {
            return this.shortcodeProviders?.secondaryNavLinkName;
        } else {
            throw new Error(`Could not populate secondary nav link`);
        }
    }

    private async getConsumerIdFromService() {
        const consumerId = await new ConsumerIdService().getConsumerId();
        if (consumerId) {
            return consumerId.consumer.id;
        } else {
            throw new Error('There was an issue getting consumer ID');
        }
    }

    private static async asyncForEach<T>(array: T[], callback: Function) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}
