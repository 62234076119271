export interface AppEnvironmentConfiguration {
    countryConfigurations: CountryConfiguration[];
}

export interface CountryConfiguration {
    name: string;
    countryCode: string;
    threeLetterCountryCode: string;
    appId: string;
    fmaUrl: string;
    fmaRegion: string;
    fmaFsUrl: string;
    dslUrl?: string;
    analyticsEmbedScriptUrl: string;
    walletUrl: string;
    subscriptionEnv: string;
    subscriptionUrl: string;
    regionConfigurations: RegionConfiguration[];
    includeDomainsInLanguageSelector?: string[];
}

export interface RegionConfiguration {
    domain: string;
    root: string;
    language: string;
    languageRegionCode: string;
    threeLetterLangCode?: string;
    langScript: string;
    blockSearchIndexing: boolean;
    brand: string;
    clientId: string;
    languageSetId: number;
    enableDebugLogs?: boolean;
    medalliaCommentCardId?: number;
}

export interface AppConfiguration {
    environment: string;
    countryName: string;
    countryCode: string;
    threeLetterCountryCode: string;
    domain: string;
    root: string;
    languageName: string;
    languageRegionCode: string;
    threeLetterLangCode?: string;
    langScript: string;
    clientId?: string;
    appId: string;
    fmaUrl: string;
    fmaRegion: string;
    fmaFsUrl: string;
    dslUrl?: string;
    walletUrl: string;
    analyticsEmbedScriptUrl: string;
    subscriptionEnv: string;
    subscriptionUrl: string;
    blockSearchIndexing: boolean;
    includeDomainsInLanguageSelector?: string[];
    brand: string;
    languageSetId: number;
    medalliaCommentCardId?: number;
    enableDebugLogs?: boolean;
}

export interface CountriesByLocale {
    [locale: string]: {
        languageName: string;
        twoLetterCountryCode: string;
        threeLetterCountryCode: string;
    };
}

export const fallbackAppConfiguration: AppConfiguration = {
    environment: 'fallback',
    countryName: 'Canada',
    countryCode: 'ca',
    threeLetterCountryCode: 'can',
    domain: 'localhost:8080',
    root: '/',
    languageName: 'English',
    langScript: 'LATN',
    languageRegionCode: 'en-ca',
    appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
    fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
    fmaFsUrl: 'https://stg.api.mps.ford.com',
    fmaRegion: 'na',
    analyticsEmbedScriptUrl:
        '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-9c6de279ddb8-staging.min.js',
    walletUrl: 'https://wwwqa.payments.ford.com/bundled.js',
    subscriptionEnv: 'QA',
    subscriptionUrl:
        'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
    blockSearchIndexing: true,
    brand: 'ford',
    languageSetId: 1,
};

export { appConfigurationProd } from './environments/app-configuration-prod';
export { appConfigurationPerf } from './environments/app-configuration-perf';
export { appConfigurationQa } from './environments/app-configuration-qa';
export { appConfigurationDev } from './environments/app-configuration-dev';
export { appConfigurationLocal } from './environments/app-configuration-local';
