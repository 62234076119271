import ServerSideService from '../server-side-service/server-side-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '../http-service/http-service';
import { SwapTokenResponse } from '@models/profile-with-vehicles';
import { findPathByAlias } from '@routes/routesList';

export default class AuthenticationService {
    private readonly AUTHENTICATED_EVENT = 'fma_authenticated';
    private readonly UNAUTHENTICATED_EVENT = 'fma_unauthenticated';
    private win: any;

    private handleAuthenticatedEvent: (() => void) | null = null;
    private handleUnauthenticatedEvent: (() => void) | null = null;
    private isAuthenticatedPromise: Promise<boolean>;

    constructor() {
        if (ServerSideService.isClientSide()) {
            this.win = window;
        }

        this.isAuthenticatedPromise = new Promise<boolean>((resolve) => {
            if (this.fmaAlreadyAuthenticated()) {
                resolve(true);
            } else if (this.fmaAlreadyAuthenticated() === false) {
                resolve(false);
            } else if (ServerSideService.isClientSide()) {
                this.addAuthenticationEventListeners(resolve);
            }
        });
    }

    private fmaAlreadyAuthenticated = (): boolean => {
        return this.win?.fma?.isAuthenticated as boolean;
    };

    public getCatBundle = (): any | null => {
        const webviewData =
            this.win !== undefined && typeof window !== undefined
                ? (window as any).SERVER_CONTEXT?.webviewData
                : {};
        if (webviewData?.accessToken) {
            return {
                access_token: webviewData.accessToken,
            };
        } else if (this.win?.webviewBundle) {
            return { access_token: this.win?.webviewBundle.accessToken };
        } else {
            return this.win?.fma?.CATBundle;
        }
    };

    public getAccessToken = (): string | null => {
        return this.win?.fma?.accessToken;
    };

    public getSwapToken = (appText: string): Promise<SwapTokenResponse> => {
        const authService = new AuthenticationService();

        const appConfigService: AppConfigurationService =
            new AppConfigurationService();

        const dslUrl = appConfigService.getAppConfiguration().dslUrl
            ? appConfigService.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.TOKEN_SWAP;

        return HttpService.put<any>(
            url,
            {
                refresh_token: authService.getCatBundle()?.refresh_token,
                app_text: appText,
            },
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            }
        ).then((response) => {
            return response.data;
        });
    };

    private addAuthenticationEventListeners = (
        resolve: (value?: boolean | PromiseLike<boolean> | undefined) => void
    ): void => {
        document.body.addEventListener(
            this.AUTHENTICATED_EVENT,
            (this.handleAuthenticatedEvent = () => {
                resolve(true);
                this.removeAuthenticationEventListeners();
            })
        );
        document.body.addEventListener(
            this.UNAUTHENTICATED_EVENT,
            (this.handleUnauthenticatedEvent = () => {
                resolve(false);
                this.removeAuthenticationEventListeners();
            })
        );
    };

    private removeAuthenticationEventListeners = (): void => {
        if (this.handleAuthenticatedEvent)
            document.body.removeEventListener(
                this.AUTHENTICATED_EVENT,
                this.handleAuthenticatedEvent
            );
        if (this.handleUnauthenticatedEvent)
            document.body.removeEventListener(
                this.UNAUTHENTICATED_EVENT,
                this.handleUnauthenticatedEvent
            );
    };

    public login = (): void => {
        sessionStorage.clear();
        this.win?.fma && this.win.fma.login();
    };

    public logout(): void {
        sessionStorage.clear();
        this.win?.fma && this.win.fma.logout();
    }

    public register = (): void => {
        sessionStorage.clear();
        this.win?.fma && this.win.fma.register();
    };

    public changeUsername = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.changeUsername();
    };

    public changePassword = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.changePassword();
    };

    public addMFAEmailAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.addEmailMfa();
    };

    public changeMFAEmailAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.editEmailMfa();
    };

    public addMFAAppAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.addTotpMfa();
    };

    public changeMFAAppAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.editTotpMfa();
    };

    public addMFASmsAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.addSmsMfa();
    };

    public changeMFASmsAuthentication = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.editSmsMfa();
    };

    public editProfile = (): void => {
        sessionStorage.removeItem('postLoginNavComplete');
        this.win?.fma && this.win.fma.editProfile();
    };

    public onIsAuthenticated = (): Promise<boolean> => {
        return this.isAuthenticatedPromise;
    };

    public getState = (): string => {
        return this.win?.fma ? this.win.fma.state : '';
    };

    public getStateFromConfig = (): string => {
        return this.win?.fma ? this.win.fma.model.config.state : '';
    };

    public updateState = (path: string): void => {
        if (this.win?.fma) this.win.fma.model.config.state = path;
    };

    public updateLogoutURL = (url: string): void => {
        if (this.win?.fma) this.win.fma.model.config.logoutURL = url;
    };

    private getOrigin(): string {
        const appConfiguration = new AppConfigurationService();

        return `https://${window.location.hostname}${
            appConfiguration.brand &&
            appConfiguration.currentLanguageRegionCode &&
            appConfiguration.currentRoot ===
                `/${appConfiguration.brand}/${appConfiguration.currentLanguageRegionCode}/`
                ? '/'
                : appConfiguration.currentRoot
        }`;
    }

    public setFmaRedirectUrl(url: string): void {
        const urlWithAccountDashboardName =
            url?.split('//')[1]?.split('/')[1] === 'account-dashboard';

        const origin = urlWithAccountDashboardName
            ? this.getOrigin() + 'account-dashboard'
            : this.getOrigin();
        const path = window.location.href.replace(origin, '');
        if (this.win?.fma) {
            this.updateState(url ? url.replace(origin, '') : path);
            this.win.fma.model.config.redirectUrl = origin;
            // set logout and origin URLs if missing(in case of syndicated header)
            !this.win.fma.model.config.logoutURL &&
                (this.win.fma.model.config.logoutURL = origin);
            !this.win.fma.model.config.originURL &&
                (this.win.fma.model.config.originURL = origin);
        }
    }

    public setFmaState() {
        if (this.win?.fma) {
            let pathName = window.location.pathname + window.location.search;
            let pagePath = '';
            const { root } = this.win.SERVER_CONTEXT;

            this.win.fma.model.config.redirectUrl =
                window.location.origin + root;

            // append a trailing slash to pathName for String manipulation
            if (!pathName.endsWith('/') && !pathName.includes('?')) {
                pathName = pathName + '/';
            }
            // pagePath should not have the root so remove it
            pagePath = pathName.replace(root, '');
            if (pagePath?.split('?')[0].length > 1) {
                if (!pagePath.startsWith('/')) pagePath = '/' + pagePath;
                this.win.fma.model.config.state = pagePath;
            } else {
                this.win.fma.model.config.state = findPathByAlias(
                    'AccountDashboardView'
                );
            }
        }
    }

    public getFmaState(): string | undefined {
        if (this.win?.fma) {
            return this.win?.fma.state;
        }
    }

    updateStateWithDeepLink() {
        const currentState = this.getStateFromConfig();
        const stateContainsQueryParam = RegExp(/.\?\w*=\w*/).test(currentState);
        if (currentState && !currentState.includes('appLogout=')) {
            if (stateContainsQueryParam) {
                this.updateState(currentState + '&appLogout=true');
            } else {
                this.updateState(currentState + '?appLogout=true');
            }
        } else if (!currentState) {
            const path = window.location.href.replace(this.getOrigin(), '');
            this.updateState(path + '?appLogout=true');
        }
    }
}
