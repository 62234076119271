import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

export enum NotificationType {
    Success = 'success',
    Error = 'error',
    Info = 'info',
    SpecialAlert = 'default',
    None = '',
    DealerConsentSuccess = 'dealer-consent-success',
}

export type NotificationContextType = {
    notificationType: NotificationType;
    notificationFlag: boolean;
    setNotificationContext?: (
        type: NotificationType,
        fromDashboard?: boolean
    ) => void;
    isFromDashboard: boolean;
    setNotificationFlag?: (flag: boolean) => void;
};

export const NotificationContext = createContext({
    notificationType: NotificationType.None,
    notificationMessage: '',
    notificationFlag: false,
    isFromDashboard: false,
    setNotificationContext: (type, fromDashboard, message?) => null,
    setNotificationFlag: (flag) => null,
});

const NotificationProvider = ({ children }) => {
    const [notificationType, setNotificationType] = useState(
        NotificationType.None
    );
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationFlag, setNotificationFlag] = useState(false);
    const [isFromDashboard, setIsFromDashboard] = useState<boolean>(false);
    const setNotificationContext = useCallback(
        (updates, fromDashboard, message?) => {
            setNotificationType(updates);
            setNotificationMessage(message);
            setNotificationFlag(!notificationFlag);
            setIsFromDashboard(fromDashboard);
        },
        [notificationType]
    );
    const getContext = useCallback(
        () => ({
            notificationType,
            notificationMessage,
            setNotificationContext,
            notificationFlag,
            setNotificationFlag,
            isFromDashboard,
        }),
        [
            notificationType,
            notificationMessage,
            setNotificationContext,
            notificationFlag,
            setNotificationFlag,
            isFromDashboard,
        ]
    );

    useEffect(() => {
        setNotificationContext(
            notificationType,
            isFromDashboard,
            notificationMessage
        );
    }, [notificationFlag]);

    return (
        <NotificationContext.Provider value={getContext()}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationProvider;
