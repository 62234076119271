import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationProd: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Origin',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-25cd2421c068.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'myfordvehicle.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 1,
                },
            ],
            includeDomainsInLanguageSelector: ['fr.ford.ca'],
        },
        {
            name: 'Canada',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-25cd2421c068.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ca',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 1,
                },
                {
                    domain: 'fr.ford.ca',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 1,
                },
                {
                    domain: 'www.lincolncanada.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'fr.lincolncanada.com',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.ca',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fr-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/lincoln/en-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/lincoln/fr-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 3,
                },
                {
                    domain: 'fr.globalaccount.ford.ca',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 4,
                },
                {
                    domain: 'fr.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 4,
                },
            ],
        },
        {
            name: 'USA',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 1,
                },
                {
                    domain: 'es.ford.com',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 1,
                },
                {
                    domain: 'www.lincoln.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'es.lincoln.com',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/lincoln/en-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/lincoln/es-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 3,
                },
                {
                    domain: 'es.globalaccount.ford.com',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 3,
                },
                {
                    domain: 'www.globalaccount.lincoln.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 4,
                },
                {
                    domain: 'es.globalaccount.lincoln.com',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 4,
                },
            ],
        },
        {
            name: 'Sweden',
            countryCode: 'se',
            threeLetterCountryCode: 'swe',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl: '',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.se',
                    root: '/',
                    language: 'Swedish',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/sv-se/',
                    language: 'Swedish',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.seaccount.ford.com',
                    root: '/',
                    language: 'Swedish',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.co.uk',
                    root: '/account-management/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                    medalliaCommentCardId: 13891,
                },
                {
                    domain: 'www.globalaccount.ford.co.uk',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13891,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-gb/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13891,
                },
            ],
        },
        {
            name: 'Ireland',
            countryCode: 'ie',
            threeLetterCountryCode: 'irl',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ie',
                    root: '/account-management/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.ie',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-ie/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Switzerland',
            countryCode: 'ch',
            threeLetterCountryCode: 'che',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.de.ford.ch',
                    root: '/mein-ford-account/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.fr.ford.ch',
                    root: '/gestion-de-compte/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.it.ford.ch',
                    root: '/gestione-account/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'de.globalaccount.ford.ch',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/de-ch/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'fr.globalaccount.ford.ch',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fr-ch/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'it.globalaccount.ford.ch',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/it-ch/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Austria',
            countryCode: 'at',
            threeLetterCountryCode: 'aut',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.at',
                    root: '/mein-ford-account/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.at',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/de-at/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Germany',
            countryCode: 'de',
            threeLetterCountryCode: 'deu',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.de',
                    root: '/mein-ford-account/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                    medalliaCommentCardId: 13855,
                },
                {
                    domain: 'www.globalaccount.ford.de',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13855,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/de-de/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13855,
                },
            ],
        },
        {
            name: 'France',
            countryCode: 'fr',
            threeLetterCountryCode: 'fra',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.fr',
                    root: '/gestion-de-compte/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                    medalliaCommentCardId: 13873,
                },
                {
                    domain: 'www.globalaccount.ford.fr',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13873,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fr-fr/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13873,
                },
            ],
        },
        {
            name: 'Luxembourg',
            countryCode: 'lu',
            threeLetterCountryCode: 'lux',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.lu',
                    root: '/gestion-de-compte/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.lu',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fr-lu/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Belgium',
            countryCode: 'be',
            threeLetterCountryCode: 'bel',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.fr.ford.be',
                    root: '/gestion-de-compte/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'fr.globalaccount.ford.be',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fr-be/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.nl.ford.be',
                    root: '/account-management/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'nl.globalaccount.ford.be',
                    root: '/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/nl-be/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Italy',
            countryCode: 'it',
            threeLetterCountryCode: 'ita',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.it',
                    root: '/gestione-account/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                    medalliaCommentCardId: 13882,
                },
                {
                    domain: 'www.globalaccount.ford.it',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13882,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/it-it/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13882,
                },
            ],
        },
        {
            name: 'Spain',
            countryCode: 'es',
            threeLetterCountryCode: 'esp',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.es',
                    root: '/gestionar-cuenta/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                    medalliaCommentCardId: 13864,
                },
                {
                    domain: 'www.globalaccount.ford.es',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13864,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-es/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                    medalliaCommentCardId: 13864,
                },
            ],
        },
        {
            name: 'Portugal',
            countryCode: 'pt',
            threeLetterCountryCode: 'prt',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.pt',
                    root: '/gestao-de-conta/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.pt',
                    root: '/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/pt-pt/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Norway',
            countryCode: 'no',
            threeLetterCountryCode: 'nor',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.no',
                    root: '/konto-administrasjon/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.no',
                    root: '/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/no-no/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Nederland',
            countryCode: 'nl',
            threeLetterCountryCode: 'nld',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.nl',
                    root: '/accountmanagement/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.nl',
                    root: '/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/nl-nl/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Finland',
            countryCode: 'fi',
            threeLetterCountryCode: 'fin',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.fi',
                    root: '/tilin-hallinta/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.fiaccount.ford.com',
                    root: '/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.fi',
                    root: '/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/fi-fi/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Denmark',
            countryCode: 'dk',
            threeLetterCountryCode: 'dnk',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.dk',
                    root: '/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/da-dk/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.dkaccount.ford.com',
                    root: '/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Czech Republic',
            countryCode: 'cz',
            threeLetterCountryCode: 'cze',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.cz',
                    root: '/sprava-uctu/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.cz',
                    root: '/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/cs-cz/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Greece',
            countryCode: 'gr',
            threeLetterCountryCode: 'grc',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.gr',
                    root: '/account-management/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.gr',
                    root: '/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/el-gr/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Hungary',
            countryCode: 'hu',
            threeLetterCountryCode: 'hun',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.hu',
                    root: '/fiokkezeles/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.hu',
                    root: '/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/hu-hu/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Poland',
            countryCode: 'pl',
            threeLetterCountryCode: 'pol',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.pl',
                    root: '/zarzadzanie-kontem/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.pl',
                    root: '/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/pl-pl/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Romania',
            countryCode: 'ro',
            threeLetterCountryCode: 'rou',
            appId: '260ea066-aa5e-4344-995d-b8a3d7dd0fca',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.ro',
                    root: '/gestionare-cont/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.ro',
                    root: '/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/ro-ro/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: '89f879a5-4474-413f-a090-46649584706d',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Australia',
            countryCode: 'au',
            threeLetterCountryCode: 'aus',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.au',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.com.au',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-au/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'New Zealand',
            countryCode: 'nz',
            threeLetterCountryCode: 'nzl',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl:
                'https://fordpay-components.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: '',
            subscriptionUrl:
                'https://www.subscriptions.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'www.ford.co.nz',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.co.nz',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-nz/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'South Africa',
            countryCode: 'za',
            threeLetterCountryCode: 'zaf',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.ford.co.za',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.co.za',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-za/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Vietnam',
            countryCode: 'vn',
            threeLetterCountryCode: 'vnm',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.vn',
                    root: '/myaccount/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.com.vn',
                    root: '/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/vi-vn/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Philippines',
            countryCode: 'ph',
            threeLetterCountryCode: 'phl',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.ford.com.ph',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.com.ph',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-ph/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Thailand',
            countryCode: 'th',
            threeLetterCountryCode: 'tha',
            appId: '2bdab9df-9151-41a1-b6f7-b9490cf8c430',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-e2bd613fff94.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.co.th',
                    root: '/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/th-th/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.th',
                    root: '/myaccount/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
                {
                    domain: 'en.globalaccount.ford.co.th',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/en-th/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 1,
                },
                {
                    domain: 'www.ford.co.th',
                    root: '/en/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'aa356d6f-0978-4e98-bfcc-15ae6bf1b774',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Mexico',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-9cb9e2b6b2e0.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.mx',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-mx/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.mx',
                    root: '/mi-cuenta/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a0c72c3b-8ede-4eff-b66a-f00d31fe3694',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.lincoln.mx',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.lincoln.com',
                    root: '/lincoln/es-mx/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
                {
                    domain: 'www.lincoln.mx',
                    root: '/mi-cuenta/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: '2fb73163-f760-42d7-87f8-a04cd758a20e',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Argentina',
            countryCode: 'ar',
            threeLetterCountryCode: 'arg',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.com.ar',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-ar/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.com.ar',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Brazil',
            countryCode: 'br',
            threeLetterCountryCode: 'bra',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-f04a53356927.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.com.br',
                    root: '/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/pt-br/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.com.br',
                    root: '/myaccount/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Chile',
            countryCode: 'cl',
            threeLetterCountryCode: 'chl',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.cl',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-cl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-cl/',
                    language: 'Spanish',
                    languageRegionCode: 'es-cl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.cl',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-cl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Colombia',
            countryCode: 'co',
            threeLetterCountryCode: 'col',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.com.co',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-co',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-co/',
                    language: 'Spanish',
                    languageRegionCode: 'es-co',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.com.co',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-co',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Peru',
            countryCode: 'pe',
            threeLetterCountryCode: 'per',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.pe',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-pe',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-pe/',
                    language: 'Spanish',
                    languageRegionCode: 'es-pe',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.pe',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-pe',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Venezuela',
            countryCode: 've',
            threeLetterCountryCode: 'ven',
            appId: 'b08429de-8440-478d-a323-7a1e05cc9844',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-2161a479f5a3.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'www.globalaccount.ford.com.ve',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ve',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.globalaccount.ford.com',
                    root: '/ford/es-ve/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ve',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
                {
                    domain: 'www.ford.com.ve',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ve',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'abf78b0a-cc04-4c84-8af7-59fbb9384bf9',
                    languageSetId: 2,
                },
            ],
        },
    ],
};
