import SmartTileIcon from '../smart-tile-icon/smart-tile-icon';
import React from 'react';
import SmartTile, { SmartTileProps } from '../smart-tile/smart-tile';
import './smart-tile-fpr-lincoln.scss';

const LincolnGenericTierIcon = './icons/lincoln-landing-icon.svg';

interface Props {
    iconPath: string;
    staticSubCopy: string;
    dynamicCTATitle?: string;
    dynamicTitle?: string;
    analyticEventName?: string;
    tierText?: string;
    useLoyaltyPointsDataForTile: any;
    isMobile: boolean;
    dynamicSubCopy: string;
}

const SmartTileFPRLincoln = (props: Props & SmartTileProps) => {
    const {
        dynamicTitle,
        dynamicCTATitle,
        analyticEventName,
        isMobile,
        dynamicSubCopy,
        tierText,
    } = props;
    const loyaltyDataForTile = props.useLoyaltyPointsDataForTile();
    const LincolnTiers = loyaltyDataForTile?.lincoln?.tierName;

    if (loyaltyDataForTile) {
        return (
            <SmartTile
                {...props}
                className="smart-tile-fpr-lincoln"
                header={dynamicTitle}
                ctaTitle={dynamicCTATitle}
                analyticEventName={analyticEventName}
                isMobile={isMobile}
            >
                <div className="labeled-number">
                    <div className="container">
                        <span className="icon">
                            <img
                                className="icon"
                                alt=""
                                src={LincolnGenericTierIcon}
                                width={'65'}
                                height={'66'}
                                loading={'lazy'}
                                fetchPriority="low"
                            />
                        </span>
                        {LincolnTiers?.length > 0 && (
                            <h5 className="tier-title fmc-type--body2">{`${LincolnTiers} ${tierText}`}</h5>
                        )}
                    </div>
                    <h6 className="fmc-type--heading6 fds-color__text--primary">
                        {dynamicTitle ? dynamicTitle : ''}
                    </h6>
                    <h6 className="fmc-type--subtitle1 fds-color__text--primary">
                        {dynamicTitle ? dynamicTitle : ''}
                    </h6>
                    <div className="number">
                        <span>
                            {loyaltyDataForTile.lincoln.points?.toLocaleString()}
                        </span>
                        <span
                            className="fmc-type--heading5"
                            style={{ marginLeft: '5px' }}
                        >
                            {dynamicSubCopy}
                        </span>
                    </div>
                </div>
            </SmartTile>
        );
    } else {
        return <SmartTileIcon {...props} />;
    }
};

export default SmartTileFPRLincoln;
